import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import DeleteVehicleSteps from 'modules/irp/modules/supplements/modules/delete_vehicle/components/DeleteSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { lazy, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import DeleteDetailsStep from './DeleteDetailsStep';
import DeleteSubmitStep from './DeleteSubmitStep';
import DeleteVerifyStep from './DeleteVerifyStep';
import DeleteVehiclePaths from './paths';

const VehicleDetailsRoute = lazy(
	() => import('modules/irp/modules/supplements/modules/delete_vehicle/routes/VehicleDetailsRoute'),
);
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function DeleteVehicleIndex() {
	const { supplementKey } = useTypedParams(DeleteVehiclePaths);
	return <Redirect to={DeleteVehiclePaths.Details.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/delete_vehicle');
	return (
		<SupplementBlocker basePath={DeleteVehiclePaths} title={t('irp/supplements/delete_vehicle:dialogs.close.title')}>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const DeleteVehicleRoutes = [
	{
		path: DeleteVehiclePaths.path,
		element: (
			<CanAccess resource="irp.supplements.delete_vehicle" action={Actions.READ} denied={<NoPermission />}>
				<SupplementPageContainer
					title="irp/supplements/delete_vehicle:title"
					breadcrumbs={['irp/supplements/delete_vehicle', 'irp/supplements', 'irp/vehicles']}
					stepRoutes={[DeleteVehiclePaths.Details.path, DeleteVehiclePaths.Verify.path, DeleteVehiclePaths.Submit.path]}
					steps={<DeleteVehicleSteps />}
				>
					<WithBlocker>
						<Outlet />
					</WithBlocker>
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('irp/supplements/delete_vehicle:title'),
		},
		children: [
			// Index route
			{
				path: DeleteVehiclePaths.path,
				index: true,
				element: <DeleteVehicleIndex />,
			},

			// Steps
			{
				path: DeleteVehiclePaths.Details.path,
				element: <DeleteDetailsStep />,
			},
			{
				path: DeleteVehiclePaths.Verify.path,
				element: <DeleteVerifyStep />,
			},
			{
				path: DeleteVehiclePaths.Submit.path,
				element: <DeleteSubmitStep />,
			},

			// Vehicle info
			{
				path: DeleteVehiclePaths.Vehicle.path,
				element: <VehicleDetailsRoute />,
				handle: {
					crumb: ({ t }) => t('irp/vehicles:details.title'),
				},
				children: [
					{
						path: DeleteVehiclePaths.Vehicle.path,
						index: true,
						element: <VehicleDetailsPlate />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Credentials.path,
						element: <VehicleDetailsCredentials />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Purchase.path,
						element: <VehicleDetailsPurchase />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Documents.path,
						element: <VehicleDetailsDocuments />,
					},
					{
						path: DeleteVehiclePaths.Vehicle.Registration.path,
						element: <VehicleDetailsRegistration />,
					} as Route<typeof DeleteVehiclePaths.Vehicle.Registration.path>,
				],
			} as Route<typeof DeleteVehiclePaths.Vehicle>,
		],
	} as Route<typeof DeleteVehiclePaths>,
];

export default DeleteVehicleRoutes;
