import { Container, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const ContainerStyled = styled(Container)(({ theme }) => ({
	flex: 1,
	padding: theme.spacing(4),
}));

export default function PageContainer({ children }: PropsWithChildren) {
	return (
		<ContainerStyled maxWidth={false} disableGutters>
			{children}
		</ContainerStyled>
	);
}
