import { GridColDef } from '@mui/x-data-grid';
import { dateFormat, dateFromObject } from 'core/services/intl';
import SupplementSubmitForm from 'modules/irp/modules/supplements/components/SupplementSubmitForm';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import Vehicle from 'modules/irp/modules/vehicles/types/Vehicle';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function DeleteVehiclesSubmitStep() {
	// Hooks
	const { t } = useTranslation('irp/supplements/delete_vehicle');
	const { supplementKey } = useTypedParams(DeleteVehiclePaths.Submit);

	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('data:vehicle.vin'), field: 'vin', minWidth: 200, flex: 1 },
		{
			headerName: t('data:vehicle.unitNumber'),
			field: 'unitNumber',
			minWidth: 75,
			flex: 1,
		},
		{
			headerName: t('data:vehicle.title.number'),
			field: 'titleNumber',
			minWidth: 125,
			flex: 1,
			renderCell: ({ row }) => row.title.number,
		},
		{
			headerName: t('data:vehicle.deactivateDate'),
			field: 'deactivateDate',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }) => (
				<span>{row.deactivate?.date ? dateFormat(dateFromObject(row.deactivate?.date)) : ''}</span>
			),
		},
		{
			headerName: t('data:vehicle.deactivateReason'),
			field: 'deleteReason',
			minWidth: 125,
			flex: 1,
			renderCell: ({ row }) => <span>{row.deactivate?.reason?.displayName}</span>,
		},
		{
			headerName: t('data:vehicle.plate.return'),
			field: 'plateReturn',
			minWidth: 125,
			flex: 1,
			renderCell: ({ row }) => row.plate?.return?.displayName || <span>&mdash;</span>,
		},
	];

	const filterRows = (searchValue: string, rows: Vehicle[]) => {
		return rows.filter((v) => {
			const search = searchValue.toLowerCase();
			return (
				v.vin.toLowerCase().includes(search) ||
				v.unitNumber.toLowerCase().includes(search) ||
				v.title?.number?.toLowerCase().includes(search) ||
				(v.deactivate?.date ? dateFormat(dateFromObject(v.deactivate?.date)).toLowerCase().includes(search) : false) ||
				v.deactivate?.reason?.displayName.toLowerCase().includes(search) ||
				(v.plate?.return?.displayName ? v.plate?.return?.displayName.toLowerCase().includes(search) : false)
			);
		});
	};

	return (
		<SupplementSubmitForm
			previousPath={DeleteVehiclePaths.Verify.buildPath({ supplementKey })}
			columns={columns}
			filterRows={filterRows}
			noCredentials
		/>
	);
}
