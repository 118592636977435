/* eslint-disable react/jsx-props-no-spreading */
import { InputProps } from '@mui/material/Input';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import InputBase from 'core/components/InputBase';

interface FormInputFieldProps extends FormControlProps {
	slotProps: FormControlProps['slotProps'] & {
		input: InputProps;
	};
}

export default function FormInputField({ name, label, helperText, tooltip, slotProps }: FormInputFieldProps) {
	return (
		<FormControl name={name} label={label} helperText={helperText} tooltip={tooltip} slotProps={slotProps}>
			<InputBase
				{...slotProps.input}
				id={name}
				name={name}
				disabled={slotProps.input.readOnly ? true : slotProps.input.disabled}
				error={slotProps.formControl?.error}
			/>
		</FormControl>
	);
}
