import { NotFound } from 'core/components';
import { useAPI } from 'core/hooks';
import { ApiError } from 'core/services/api';
import WeightGroupsService from 'modules/irp/modules/weight_groups/api/WeightGroupsService';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import WeightGroup, { WeightGroupIncludes } from 'types/WeightGroup';

type WeightGroupContextProps = {
	loading: boolean;
	weightGroup: WeightGroup | null;
};

export interface WeightGroupProviderProps {
	weightGroupKey: string;
	include?: WeightGroupIncludes;
}

const DefaultWeightGroupContext: WeightGroupContextProps = {
	loading: true,
	weightGroup: null,
};

const WeightGroupContext = createContext<WeightGroupContextProps>(DefaultWeightGroupContext);

export function WeightGroupProvider({
	weightGroupKey,
	include,
	children,
}: PropsWithChildren<WeightGroupProviderProps>) {
	const weightGroupsService = useAPI(WeightGroupsService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [weightGroup, setWeightGroup] = useState<WeightGroup | null>(null);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		// Load once
		if (!weightGroupKey) return;

		weightGroupsService
			.get(weightGroupKey, include)
			.then(setWeightGroup)
			.catch((err) => {
				if (err instanceof ApiError) {
					setError(err);
					return;
				}

				throw err;
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weightGroupKey]);

	const value = useMemo<WeightGroupContextProps>(() => {
		return { loading, weightGroup };
	}, [loading, weightGroup]);

	// If there is an error, display it
	if (error) return <NotFound />;

	return <WeightGroupContext.Provider value={value}>{children}</WeightGroupContext.Provider>;
}

export function useWeightGroup(): WeightGroupContextProps {
	const context = useContext(WeightGroupContext);
	if (context === DefaultWeightGroupContext) {
		throw new Error('useWeightGroup must be used within a WeightGroupProvider');
	}

	return context;
}
