import { Box } from '@mui/material';
import { Loader } from 'core/components';
import { Module } from 'core/types/module';
import { Route } from 'core/types/routing';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import RazorPaths from './paths';

const razorUrl = process.env.REACT_APP_CLEARFLEET_RAZOR_URL || '';

// RazorRedirector component
function RazorRedirector() {
	const params = useParams<{ '*': string }>();
	const [search] = useSearchParams();

	// Redirect to the Razor URL
	useEffect(() => {
		window.location.replace(`${razorUrl}/${params['*']}${search.size ? `?${search.toString()}` : ''}`);
	}, [params, search]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
			<Loader />
		</Box>
	);
}

// Razor module is a temporary module that will be removed in the future
// It's purpose is to handle navigation from within React (not from the server)
// to the Razor application. This is needed to support React Router blockers.
const RazorModule: Module = {
	// ID of the module
	id: 'razor',

	// Routes
	routes: [
		{
			path: `${RazorPaths.path}/*`,
			element: <RazorRedirector />,
		} as Route<typeof RazorPaths>,
	],
};

export default RazorModule;
