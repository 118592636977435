import { PermissionsContext, canAccess as permissionsCanAccess } from 'core/services/permissions';
import { Action } from 'core/types/permissions';
import { useContext } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function usePermissions() {
	const { permissions, refreshPermissions, loading } = useContext(PermissionsContext);

	const canAccess = (resource: string, action: Action, primaryKey?: string) =>
		permissionsCanAccess(permissions, resource, action, primaryKey);

	return {
		canAccess,
		refreshPermissions,
		loading,
	};
}
