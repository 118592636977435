import { styled } from '@mui/material';
import MuiOutlinedInput from '@mui/material/OutlinedInput';

const InputBase = styled(MuiOutlinedInput)(({ theme }) => ({
	'&': {
		backgroundColor: '#f4f6f9',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#e7e9ec',
			transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
		},
	},
	'&.Mui-readOnly': {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	'&.MuiInputBase-multiline': {
		padding: 0,
	},
	'& .MuiInputBase-input': {
		padding: theme.spacing(1.25, 1.5),
		transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

		'&.MuiInputBase-inputAdornedStart': {
			paddingLeft: 0,
		},
		'&.MuiInputBase-inputAdornedEnd': {
			paddingRight: 0,
		},
		'&.Mui-disabled': {
			cursor: 'not-allowed',
		},
		'&.Mui-readOnly': {
			cursor: 'default',
			color: theme.typography.body2.color,
			paddingLeft: 0,
			paddingRight: 0,
			WebkitTextFillColor: theme.typography.body2.color,
		},
	},
	'.MuiInputAdornment-root': {
		marginTop: '0 !important',
	},
}));

export default InputBase;
