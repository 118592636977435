import { Module } from 'core/types/module';
import HistoryRoutes from './routes';

const HistoryModule: Module = {
	// ID of the module
	id: 'history',

	// Navigation bar items
	nav: [],

	// Routes
	routes: HistoryRoutes,
};

export default HistoryModule;
