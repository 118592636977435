import { ToastProps } from 'core/types/toast';

// Export for use outside of React components
// eslint-disable-next-line import/no-mutable-exports
export let openToast: (props: ToastProps) => void = () => {
	// No-op
};

export function setOpenToastFunction(func: (props: ToastProps) => void) {
	openToast = func;
}
