import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { route, string, useTypedSearchParams } from 'react-router-typesafe-routes/dom';

type RazorContextProps = {
	accountKey: string | null;
	setAccountKey: (k: string) => void;
};

const DefaultRazorContext: RazorContextProps = {
	accountKey: null,
	setAccountKey: () => {
		/* do nothing */
	},
};

const RazorContext = createContext<RazorContextProps>(DefaultRazorContext);

export const RazorSessionRoutes = route('', {
	searchParams: {
		accountKey: string(),
	},
});

// Provider for the session sync/transfer from the Razor ClearFleet application
export function RazorProvider({ children }: PropsWithChildren) {
	// Hooks
	const [{ accountKey: accountKeyFromSearch }] = useTypedSearchParams(RazorSessionRoutes);

	// State
	const [accountKey, setAccountKey] = useState<string | null>(accountKeyFromSearch || null);

	// Set account key if provided in the URL
	useEffect(() => {
		if (accountKeyFromSearch) setAccountKey(accountKeyFromSearch);
	}, [accountKeyFromSearch]);

	const value = useMemo(() => ({ accountKey, setAccountKey }), [accountKey, setAccountKey]);

	return <RazorContext.Provider value={value}>{children}</RazorContext.Provider>;
}

export function useRazor(): RazorContextProps {
	const context = useContext(RazorContext);
	if (context === DefaultRazorContext) {
		throw new Error('useRazor must be used within a RazorProvider');
	}

	return context;
}
