import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface SupplementApproveAndInvoiceProps {
	value: boolean;
	onChange: (value: boolean) => void;
	disabled?: boolean;
}

export default function SupplementApproveAndInvoice({
	value,
	onChange,
	disabled,
}: PropsWithChildren<SupplementApproveAndInvoiceProps>) {
	// Hooks
	const { t } = useTranslation('irp/supplements');

	return (
		<Grid container alignItems="center">
			<Grid item sm={12} lg={6}>
				<InputLabel>{t('submit.approveInvoice')}</InputLabel>
			</Grid>
			<Grid item sm={12} lg={6}>
				<FormControl disabled={disabled}>
					<RadioGroup row name="approved" value={value} onChange={(e) => onChange(e.target.value === 'true')}>
						<FormControlLabel value label={t('core:data.yes')} control={<Radio />} />
						<FormControlLabel value={false} label={t('core:data.no')} control={<Radio />} />
					</RadioGroup>
				</FormControl>
			</Grid>
		</Grid>
	);
}
