import { ThemeOptions, createTheme } from '@mui/material';
import coreTheme from 'core/theme';

const theme = createTheme(coreTheme, {
	typography: {
		h1: {
			fontSize: '3.6rem',
		},
	},
	navbar: {
		sidebar: {
			backgroundColor: '#2F3437',
		},
		backgroundColor: {
			main: '#2F3437',
		},
		color: {
			inverted: '#2F3437',
		},
	},
} as ThemeOptions);

export default theme;
