import { SxProps, Theme, styled } from '@mui/material';
import { Piece } from './Piece';
import { cubeSize } from './config';

const maxZIndex = 100000;

// CSS rubik's cube loader
// Adapted from: https://codepen.io/Roboe/pen/wymVwO

export interface LoaderProps {
	// Show the loader overlayed across the entire window
	// with a semi-transparent background
	fullScreen?: boolean;

	// Custom styles
	sx?: SxProps<Theme>;
}

const RubiksLoader = styled('div')(() => ({
	height: cubeSize,
	width: cubeSize,
	perspective: cubeSize,
	position: 'relative',
}));

const Cube = styled('div')(() => ({
	display: 'inline-block',
	width: '100%',
	height: '100%',
	fontSize: 0,
	transformStyle: 'preserve-3d',
}));

const FullscreenWrapper = styled('div')(() => ({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	backgroundColor: 'rgba(255, 255, 255, 0.5)',
	zIndex: maxZIndex,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const InlineWrapper = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export function Loader({ fullScreen, sx }: LoaderProps) {
	const Wrapper = fullScreen ? FullscreenWrapper : InlineWrapper;

	return (
		<Wrapper sx={sx}>
			<RubiksLoader>
				<Cube>
					{/* base position */}
					<Piece face="front" row="top" col="left" color="dark" />
					<Piece face="front" row="top" col="center" color="main" />
					<Piece face="front" row="top" col="right" color="light" />
					<Piece face="front" row="center" col="left" color="light" />
					<Piece face="front" row="center" col="center" color="main" />
					<Piece face="front" row="center" col="right" color="light" />
					<Piece face="front" row="bottom" col="left" color="light" />
					<Piece face="front" row="bottom" col="center" color="dark" />
					<Piece face="front" row="bottom" col="right" color="dark" />

					{/* first step: E, equator inverted */}
					<Piece face="down" row="top" col="center" color="main" />
					<Piece face="down" row="center" col="center" color="dark" />
					<Piece face="down" row="bottom" col="center" color="light" />

					{/* second step: M, middle */}
					<Piece face="right" row="center" col="left" color="dark" />
					<Piece face="right" row="center" col="center" color="main" />
					<Piece face="right" row="center" col="right" color="light" />

					{/* third step: L, left */}
					<Piece face="up" row="top" col="left" color="dark" />
					<Piece face="up" row="center" col="left" color="light" />
					<Piece face="up" row="bottom" col="left" color="main" />

					{/* fourth step: D, down */}
					<Piece face="left" row="bottom" col="left" color="light" />
					<Piece face="left" row="bottom" col="center" color="dark" />
					<Piece face="left" row="bottom" col="right" color="dark" />
				</Cube>
			</RubiksLoader>
		</Wrapper>
	);
}

Loader.defaultProps = {
	fullScreen: false,
};
