import { route } from 'react-router-typesafe-routes/dom';

const AddFleetPaths = route(
	'irp/supplements/:supplementKey/add-fleet',
	{},
	{
		Details: route(
			'details',
			{},
			{
				Jurisdictions: route('jurisdictions'),
			},
		),
		WeightGroups: route(
			'weight-groups',
			{},
			{
				WeightGroup: route(':weightGroupKey'),
			},
		),

		Vehicles: route(
			'vehicles',
			{},
			{
				Vehicle: route(
					':vehicleKey',
					{},
					{
						Info: route('info'),
						WeightGroup: route('weight-group'),
						Registration: route('registration'),
						View: route(
							'view',
							{},
							{
								Credentials: route('credentials'),
								Purchase: route('purchase'),
								Documents: route('documents'),
								Registration: route('registration'),
							},
						),
					},
				),
			},
		),

		Documentation: route('documentation'),
		Verify: route('verify'),
		Submit: route('submit'),
	},
);

export default AddFleetPaths;
