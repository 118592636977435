import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';

const ReportsModule: Module = {
	// ID of the module
	id: 'reports',

	// Navigation bar items
	nav: [
		{
			id: 'reports',
			to: RazorPaths.Reports.path,
		},
	],
};

export default ReportsModule;
