import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import FleetsPageContainer from 'modules/irp/modules/fleets/components/FleetsPageContainer';
import { FleetProvider } from 'modules/irp/modules/fleets/providers/FleetProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { FleetIncludes } from 'types/Fleet';
import FleetPaths from './paths';

const FleetsIndex = lazy(() => import('./FleetsIndex'));
const FleetEdit = lazy(() => import('./FleetEdit'));
const FleetDetails = lazy(() => import('./FleetDetailsRoute'));

// Weight Group provider from path params
export function FleetProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(FleetPaths.Fleet);
	const include: FleetIncludes = useMemo(() => ({ mileage: true }), []);

	return (
		<FleetProvider fleetKey={params.key} includes={include}>
			{children}
		</FleetProvider>
	);
}

// Route component mappings
const FleetsRoutes = [
	{
		path: FleetPaths.path,
		element: (
			<CanAccess resource="irp.fleets" action={Actions.READ} denied={<NoPermission />}>
				<FleetsPageContainer title="irp/fleets:title" breadcrumbs={['irp/fleets']}>
					<Outlet />
				</FleetsPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('irp/fleets:title'),
		},
		children: [
			// Index route
			{
				path: FleetPaths.path,
				index: true,
				element: <FleetsIndex />,
			} as Route<typeof FleetPaths>,

			// Fleet
			{
				path: FleetPaths.Fleet.path,
				element: (
					<FleetProviderFromPath>
						<Outlet />
					</FleetProviderFromPath>
				),
				children: [
					// Details
					{
						index: true,
						path: FleetPaths.Fleet.path,
						element: <FleetDetails />,
						handle: {
							crumb: ({ t }) => t('irp/fleets:details.title'),
						},
					} as Route<typeof FleetPaths.Fleet>,

					// Edit
					{
						path: FleetPaths.Fleet.Edit.path,
						element: <FleetEdit />,
						handle: {
							crumb: ({ t }) => t('irp/fleets:edit.title'),
						},
					} as Route<typeof FleetPaths.Fleet.Edit>,
				],
			} as Route<typeof FleetPaths.Fleet>,
		],
	} as Route<typeof FleetPaths>,
];

export default FleetsRoutes;
