import { Module } from 'core/types/module';
import AccountsModule from './accounts';
import AdminModule from './admin';
import HistoryModule from './history';
import HomeModule from './home';
import IFTAModule from './ifta';
import IRPModule from './irp';
import PaymentsModule from './payments';
import RazorModule from './razor';
import RefundsModule from './refunds';
import ReportsModule from './reports';
import TransmittalsModule from './transmittals';

// List of all enabled modules
// NOTE: The order in which modules are listed here
// 		   determines the order in which they appear in
//       the navigation and/or sidebar.
const modules: Module[] = [
	HomeModule,
	RazorModule,
	PaymentsModule,
	AccountsModule,
	IRPModule,
	IFTAModule,
	HistoryModule,
	ReportsModule,
	RefundsModule,
	TransmittalsModule,
	AdminModule,
];

export default modules;
