import ApiService from 'core/services/api';
import { Permission } from 'core/types/permissions';

export default class PermissionsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/permissions`);
	}

	async get(): Promise<Permission[]> {
		const { permissions } = await this.GET<{ permissions: Permission[] }>('');
		return permissions;
	}
}
