import DialogContentText from '@mui/material/DialogContentText';
import Dialog from 'core/components/Dialog';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function TransferVehicleContainer({ children }: PropsWithChildren) {
	const { t } = useTranslation(['irp/supplements/transfer_vehicle']);
	const { supplementKey, deleteVehicleKey } = useTypedParams(TransferVehiclePaths.Vehicle);

	const isWorkflow = (pathname: string) =>
		pathname.startsWith(TransferVehiclePaths.Vehicle.buildPath({ supplementKey, deleteVehicleKey })) &&
		!pathname.startsWith(TransferVehiclePaths.Vehicle.View.buildPath({ supplementKey, deleteVehicleKey }));

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (nextLocation.state?.bypass) return false;

		return isWorkflow(currentLocation.pathname) && !isWorkflow(nextLocation.pathname);
	});

	return (
		<>
			{children}
			<Dialog
				title={t('dialogs.close_detail.title')}
				severity="error"
				maxWidth="xs"
				isOpen={blocker.state === 'blocked'}
				setIsOpen={() => blocker.reset && blocker.reset()}
				confirmLabel={t('core:buttons.close')}
				cancelLabel={t('core:buttons.stay')}
				onConfirm={() => blocker.proceed && blocker.proceed()}
			>
				<DialogContentText>{t('dialogs.close_detail.description')}</DialogContentText>
			</Dialog>
		</>
	);
}
