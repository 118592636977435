import { Module } from 'core/types/module';
import HomeRoutes from './routes';

const HomeModule: Module = {
	// Module ID
	id: 'home',

	// Routes
	routes: HomeRoutes,
};

export default HomeModule;
