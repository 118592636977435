import { route } from 'react-router-typesafe-routes/dom';

const WeightGroupChangePaths = route(
	'irp/supplements/:supplementKey/edit-weight-group',
	{},
	{
		Details: route('details'),
		Documentation: route('documentation'),
		Verify: route('verify'),
		Submit: route('submit'),
	},
);

export default WeightGroupChangePaths;
