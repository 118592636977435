import { route } from 'react-router-typesafe-routes/dom';

const DeleteVehiclePaths = route(
	'irp/supplements/:supplementKey/delete-vehicle',
	{},
	{
		Details: route('details'),
		Verify: route('verify'),
		Submit: route('submit'),
		Vehicle: route(
			'vehicles/:vehicleKey',
			{},
			{
				Credentials: route('credentials'),
				Purchase: route('purchase'),
				Documents: route('documents'),
				Registration: route('registration'),
			},
		),
	},
);

export default DeleteVehiclePaths;
