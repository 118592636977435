import BlockIcon from '@mui/icons-material/Block';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ErrorProps {
	title: string;
	messages?: string[];
}

const Container = styled(Box)(({ theme }) => ({
	margin: theme.spacing(5),
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}));

export default function Error({ title, messages }: ErrorProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Container>
			<BlockIcon sx={{ color: theme.palette.error.main, fontSize: 64, marginBottom: '1rem' }} />

			<Typography variant="h3" mb={4}>
				{title}
			</Typography>

			{(messages || []).map((msg) => (
				<Typography key={msg} variant="subtitle1" paragraph>
					{msg}
				</Typography>
			))}

			<Button sx={{ marginTop: '2rem' }} onClick={() => navigate('/')}>
				{t('core:buttons.return_home')}
			</Button>
		</Container>
	);
}
