import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';

const IFTAModule: Module = {
	// ID of the module
	id: 'ifta',

	// Navigation bar items
	nav: [
		{
			id: 'license-details',
			label: 'License Details',
			to: RazorPaths.Manage.IftaLicense.path,
		},
		{
			id: 'decals-vehicles',
			label: 'Decals & Vehicles',
			to: RazorPaths.Manage.Decals.path,
		},
		{
			id: 'quarterly-return-file',
			label: 'File Quarterly Return',
			to: RazorPaths.Manage.IftaQuarterlyReturn.path,
		},
		{
			id: 'quarterly-returns',
			label: 'Quarterly Returns',
			to: RazorPaths.Manage.QuarterlyReturns.path,
		},
		{
			id: 'bonds',
			label: 'Bonds',
			to: RazorPaths.Manage.Bonds.path,
		},
	],
};

export default IFTAModule;
