import { TFunction } from 'i18next';
import * as Yup from 'yup';
import LookupValue from './LookupValue';
import { State, StateFields } from './State';

export interface Address {
	type?: LookupValue;
	line1: string;
	line2?: string;
	county?: string;
	city: string;
	state: State | null;
	postalCode: string;
	country: string;
}

export interface AddressFields {
	line1: string;
	line2?: string;
	county?: string;
	city: string;
	state: StateFields;
	postalCode: string;
	// Country is inferred from state
}

export enum AddressType {
	// Mailing or shipping address
	Mailing = 'M',

	// Physical address, sometimes referred to as a business address
	Physical = 'P',
}

export enum PhysicalDeliveryType {
	MailingAddress = 'MailMailingAddress',
	BusinessAddress = 'MailBusinessAddress',
	OtherAddress = 'MailOtherAddress',
	PickupAddress = 'Pickup',
}

export const AddressValidationSchema = ({ t }: { t: TFunction }) => ({
	line1: Yup.string()
		.max(100, t('core:data.validation.len.max', { len: 100 }))
		.required(t('core:data.validation.required')),
	line2: Yup.string(),
	county: Yup.string().max(50, t('core:data.validation.len.max', { len: 50 })),
	city: Yup.string()
		.max(50, t('core:data.validation.len.max', { len: 50 }))
		.required(t('core:data.validation.required')),
	state: Yup.object<LookupValue>()
		.shape({
			id: Yup.number().required(t('core:data.validation.required')),
		})
		.required(t('core:data.validation.required')),
	postalCode: Yup.string()
		.matches(/^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, t('core:data.validation.zip'))
		.required(t('core:data.validation.required')),
});
