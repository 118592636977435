import ApiService from 'core/services/api';
import { Address } from 'types/Address';
import LookupValue from 'types/LookupValue';
import { State } from 'types/State';

// TODO move to Clients module at some point
export default class ClientsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/clients`);
	}

	async getStates(): Promise<State[]> {
		const path = '/config/states';
		const { states } = await this.GET<{ states: State[] }>(path);

		return states;
	}

	async listPhysicalDeliveryMethods(): Promise<LookupValue[]> {
		const path = '/config/physicalDeliveryMethods';
		const { physicalDeliveryMethods } = await this.GET<{ physicalDeliveryMethods: LookupValue[] }>(path);

		return physicalDeliveryMethods;
	}

	async listAddresses(): Promise<Address[]> {
		const path = '/config/addresses';
		const { addresses } = await this.GET<{ addresses: Address[] }>(path);

		return addresses;
	}
}
