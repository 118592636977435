import { useAPI } from 'core/hooks';
import ClientsService from 'modules/irp/modules/supplements/api/ClientsService';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Address } from 'types/Address';
import LookupValue from 'types/LookupValue';
import { State } from 'types/State';

type ClientContextProps = {
	loading: boolean;
	physicalDeliveryMethods: LookupValue[];
	clientAddresses: Address[];
	states: State[];
};

const DefaultClientContext: ClientContextProps = {
	loading: true,
	physicalDeliveryMethods: [],
	clientAddresses: [],
	states: [],
};

const ClientContext = createContext<ClientContextProps>(DefaultClientContext);

export function ClientProvider({ children }: PropsWithChildren) {
	const clientsService = useAPI(ClientsService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [physicalDeliveryMethods, setPhysicalDeliveryMethods] = useState<LookupValue[]>([]);
	const [clientAddresses, setClientAddresses] = useState<Address[]>([]);
	const [states, setStates] = useState<State[]>([]);

	// Load data once
	useEffect(() => {
		setLoading(true);
		Promise.all([
			// TODO optimize to only load this when it's needed the first time
			clientsService.getStates().then(setStates),
			clientsService.listPhysicalDeliveryMethods().then(setPhysicalDeliveryMethods),
			clientsService.listAddresses().then(setClientAddresses),
		]).finally(() => setLoading(false));
	}, [clientsService]);

	const value = useMemo<ClientContextProps>(() => {
		return { loading, physicalDeliveryMethods, clientAddresses, states };
	}, [loading, physicalDeliveryMethods, clientAddresses, states]);

	return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
}

export function useClient(): ClientContextProps {
	const context = useContext(ClientContext);
	if (context === DefaultClientContext) {
		throw new Error('useClient must be used within a ClientProvider');
	}

	return context;
}
