import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import WeightGroupsPageContainer from 'modules/irp/modules/weight_groups/components/WeightGroupsPageContainer';
import { WeightGroupProvider } from 'modules/irp/modules/weight_groups/providers/WeightGroupProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { WeightGroupIncludes } from 'types/WeightGroup';
import WeightGroupPaths from './paths';

const WeightGroupsIndex = lazy(() => import('./WeightGroupsIndex'));
const WeightGroupAdd = lazy(() => import('./WeightGroupAdd'));
const WeightGroupEdit = lazy(() => import('./WeightGroupEdit'));
const WeightGroupDetails = lazy(() => import('./WeightGroupDetails'));

// Weight Group provider from path params
export function WeightGroupProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(WeightGroupPaths.View);
	const include: WeightGroupIncludes = useMemo(() => ({ fleet: true, operatingJurisdictions: true }), []);

	return (
		<WeightGroupProvider weightGroupKey={params.key} include={include}>
			{children}
		</WeightGroupProvider>
	);
}

// Route component mappings
const WeightGroupsRoutes = [
	{
		path: WeightGroupPaths.path,
		element: (
			<CanAccess resource="irp.weightGroups" action={Actions.READ} denied={<NoPermission />}>
				<WeightGroupsPageContainer title="irp/weight_groups:title" breadcrumbs={['irp/weight_groups']}>
					<Outlet />
				</WeightGroupsPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('irp/weight_groups:title'),
		},
		children: [
			// Index route
			{
				path: WeightGroupPaths.path,
				index: true,
				element: <WeightGroupsIndex />,
			} as Route<typeof WeightGroupPaths>,

			// View
			{
				path: WeightGroupPaths.View.path,
				element: (
					<WeightGroupProviderFromPath>
						<WeightGroupDetails />
					</WeightGroupProviderFromPath>
				),
				handle: {
					crumb: ({ t }) => t('irp/weight_groups:details.title'),
				},
			} as Route<typeof WeightGroupPaths.View>,

			// Add
			{
				path: WeightGroupPaths.Add.path,
				element: <WeightGroupAdd />,
				handle: {
					crumb: ({ t }) => t('irp/weight_groups:add.title'),
				},
			} as Route<typeof WeightGroupPaths.Add>,

			// Edit
			{
				path: WeightGroupPaths.Edit.path,
				element: (
					<WeightGroupProviderFromPath>
						<WeightGroupEdit />
					</WeightGroupProviderFromPath>
				),
				handle: {
					crumb: ({ t }) => t('irp/weight_groups:edit.title'),
				},
			} as Route<typeof WeightGroupPaths.Edit>,
		],
	} as Route<typeof WeightGroupPaths>,
];

export default WeightGroupsRoutes;
