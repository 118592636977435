// Base unit for the cube, use this to scale proportionally
export const baseUnit = 10; // 10px

// Individual cube piece sizes
export const pieceSize = 3 * baseUnit;

// Vertical and horizontal spacing between the pieces
export const pieceSpacing = 1 * baseUnit;

// Total size of the cube, 3x3 with spacing between each piece
export const cubeSize = 3 * pieceSize + 4 * pieceSpacing;

// Full animation duration for each step
export const animationDuration = '1.5s';
