import { number, route, string } from 'react-router-typesafe-routes/dom';

const TransmittalPaths = route(
	'transmittals',
	{},
	{
		Program: route(
			':program',
			{ params: { program: string().defined() } },
			{
				Edit: route(':id', { params: { id: number() } }),
			},
		),
	},
);

export default TransmittalPaths;
