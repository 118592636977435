import { useAPI } from 'core/hooks';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import SupplementFeesDataGrid from 'modules/irp/modules/supplements/components/SupplementFeesDataGrid';
import SupplementVerifyForm, {
	SupplementVerifyFormProps,
} from 'modules/irp/modules/supplements/components/SupplementVerifyForm';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function DeleteVehiclesVerifyStep() {
	// Hooks
	const navigate = useNavigate();
	const { supplementKey } = useTypedParams(DeleteVehiclePaths.Verify);
	const supplementsService = useAPI(SupplementsService);
	const { supplement, update: setSupplement } = useSupplement();

	// Submit handler
	const handleSubmit: SupplementVerifyFormProps['onSubmit'] = async (fields) => {
		if (!supplement) return Promise.resolve();

		return supplementsService.updateSupplement(supplement.key, fields).then((s) => {
			setSupplement(s);
			navigate(DeleteVehiclePaths.Submit.buildPath({ supplementKey }));
		});
	};

	return (
		<form name="addVehicleForm" noValidate>
			<SupplementVerifyForm
				previousPath={DeleteVehiclePaths.Details.buildPath({ supplementKey })}
				onSubmit={handleSubmit}
				feeGrid={<SupplementFeesDataGrid supplement={supplement} columnHeaders={['refund', 'plateFee']} />}
				noCredentials
			/>
		</form>
	);
}
