import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps, { SupplementStepProps } from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function NewAccountSteps() {
	const { t } = useTranslation([
		'irp',
		'irp/supplements/new_account',
		'irp/supplements',
		'irp/fleets',
		'irp/weight_groups',
	]);

	const { supplementKey } = useTypedParams(NewAccountPaths);

	const steps: SupplementStepProps['steps'] = [
		{
			label: t('irp/supplements/new_account:registration.step'),
			route: NewAccountPaths.Registration.buildPath({ supplementKey }),
		},
		{
			label: t('irp/fleets:details.title'),
			route: NewAccountPaths.Fleet.buildPath({ supplementKey }),
			activeRoutes: [
				NewAccountPaths.Fleet.Details.buildPath({ supplementKey }),
				NewAccountPaths.Fleet.Jurisdictions.buildPath({ supplementKey }),
			],
		},
		{ label: t('irp/weight_groups:title'), route: NewAccountPaths.WeightGroups.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:vehicles.step'), route: NewAccountPaths.Vehicles.buildPath({ supplementKey }) },
		{
			label: t('irp/supplements:documentation.step'),
			route: NewAccountPaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('irp/supplements:verify.step'), route: NewAccountPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:submit.step'), route: NewAccountPaths.Submit.buildPath({ supplementKey }) },
	];

	return <SupplementSteps steps={steps} />;
}
