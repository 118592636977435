import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';
import AccountsRoutes from './routes';

const AccountsModule: Module = {
	// ID of the module
	id: 'accounts',

	// Navigation bar items
	nav: [
		{
			id: 'account-info',
			label: 'Account Information',
			to: RazorPaths.Manage.Account.path,
		},
		{
			id: 'account-settings',
			label: 'Account Settings',
			to: RazorPaths.Manage.AccountSettings.path,
		},
		{
			id: 'correspondence',
			label: 'Correspondence',
			to: RazorPaths.Correspondence.History.path,
		},
		{
			id: 'escrow',
			label: 'Escrow',
			to: RazorPaths.Manage.Escrow.path,
		},
		{
			id: 'service-providers',
			label: 'Service Providers',
			to: RazorPaths.Manage.ServiceProviders.path,
		},
		{
			id: 'new',
			label: 'Create New Account',
			to: RazorPaths.Login.CreateAccount.path + RazorPaths.Login.CreateAccount.buildSearch({ new: '1' }),
		},
	],

	routes: AccountsRoutes,

	// Permission
	// permission: { resource: 'accounts', action: Actions.READ },
};

export default AccountsModule;
