import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { MouseEvent, useState } from 'react';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({ color: theme.palette.primary.main }));

export interface ActionMenuOption {
	id: string;
	label: string;
}

interface ActionMenuProps {
	options: ActionMenuOption[];
	onClick: (option: ActionMenuOption) => void;
}

export default function ActionMenu({ options, onClick }: ActionMenuProps) {
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

	return (
		<>
			<IconButton size="small" onClick={(event: MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget)}>
				<MoreVertIcon color="primary" />
			</IconButton>
			<Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
				{options.map((option) => (
					<MenuItem
						key={option.id}
						onClick={() => {
							setMenuAnchor(null);
							onClick(option);
						}}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
