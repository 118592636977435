import { route } from 'react-router-typesafe-routes/dom';

export interface WithSupplementKey {
	supplementKey: string;
}

const SupplementPaths = route(
	'irp/supplements',
	{},
	{
		Supplement: route(':supplementKey'),
	},
);

export default SupplementPaths;
