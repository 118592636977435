import { numberFormat } from 'core/services/intl';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import Fleet from './Fleet';
import LookupValue, { LookupValueID, LookupValueValidationSchema } from './LookupValue';
import { OperatingJurisdiction, OperatingJurisdictionFields } from './OperatingJurisdiction';
import Tracking from './Tracking';

export const WeightGroupMinGrossWeight = 2_000;
export const WeightGroupMaxGrossWeight = 200_000;
export const WeightGroupTrailerMaxWeight = 34_000;

export const WeightGroupNew = 'new';

export default interface WeightGroup {
	id: number;
	key: string;
	number: number;
	type: LookupValue;
	description: string;
	maxGrossWeight: number;
	active: boolean;
	activeVehicles: number;
	pendingVehicles: number;
	status: LookupValue;
	tracking: Tracking;
	varianceReason?: string;
	operatingJurisdictions: OperatingJurisdiction[];
	fleet?: Fleet;
}

export enum WeightGroupType {
	PowerUnit = 'P',
	TrailerUnit = 'T',
}

export enum WeightGroupStatus {
	Active = 'A',
	Inactive = 'I',
}

export interface WeightGroupIncludes {
	fleet?: boolean;
	operatingJurisdictions?: boolean;
}

export interface WeightGroupFields {
	description?: string;
	maxGrossWeight?: number;
	operatingJurisdictions?: OperatingJurisdictionFields[];
	type?: LookupValueID | null;
	varianceReason?: string;
}

export interface WeightGroupChangeSupplementDetails {
	weightGroup: WeightGroup;
}

export function WeightGroupValidationSchema(creating: boolean, t: TFunction) {
	const maxGrossWeightBetween = t('core:data.validation.between', {
		min: numberFormat(WeightGroupMinGrossWeight),
		max: `${numberFormat(WeightGroupMaxGrossWeight)} ${t('core:data.units.pounds')}`,
	});

	return Yup.object<WeightGroupFields>().shape({
		type: creating
			? Yup.object().shape(LookupValueValidationSchema).required(t('core:data.validation.required'))
			: Yup.object().strip(),
		description: Yup.string().required(t('core:data.validation.required')),
		maxGrossWeight: Yup.number()
			.required(t('core:data.validation.required'))
			.min(WeightGroupMinGrossWeight, maxGrossWeightBetween)
			.max(WeightGroupMaxGrossWeight, maxGrossWeightBetween),
		operatingJurisdictions: Yup.array()
			.defined()
			.of(
				Yup.object().shape({
					jurisdiction: Yup.object().shape({
						id: Yup.number().defined(),
					}),
					baseWeight: Yup.number()
						.defined()
						.transform((v) => (!v ? 0 : v))
						.default(0)
						.min(0, t('core:data.validation.required')),
				}),
			),

		varianceReason: Yup.string().when('operatingJurisdictions', {
			is: (operatingJurisdictions: OperatingJurisdictionFields[]) => {
				const weights = operatingJurisdictions.map((oj) => oj.baseWeight);
				const maxWeight = Math.max(...weights);
				const minWeight = Math.min(...weights);
				const difference = maxWeight - minWeight;

				return difference > WeightGroupMaxGrossWeight * 0.1;
			},
			then: (schema) => schema.required(t('core:data.validation.required')),
			otherwise: (schema) => schema.strip(),
		}),
	});
}
