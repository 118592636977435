import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Title from 'core/components/Title';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import { PROGRAM } from 'modules/transmittals/shared';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import TransmittalPaths from './paths';

const Transmittal = lazy(() => import('./Transmittal'));
const Home = lazy(() => import('./Home'));

function WithProgram() {
	const params = useTypedParams(TransmittalPaths.Program);
	return (
		<CanAccess resource="transmittals" action={Actions.READ}>
			<Transmittal program={params.program?.toUpperCase() as PROGRAM} />
		</CanAccess>
	);
}

// Route component mappings
const TransmittalRoutes = [
	{
		path: TransmittalPaths.path,
		element: (
			<CanAccess resource="transmittals" action={Actions.READ} denied={<NoPermission />}>
				<Title title={(t) => `${t('transmittals:title')} - ${t('core:title')}`} ns={['transmittals']} />
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('transmittals:title'),
		},
		children: [
			{
				path: TransmittalPaths.path,
				element: <Home />,
				index: true,
			},
			{
				path: TransmittalPaths.Program.path,
				element: <WithProgram />,
				handle: {
					crumb: ({ params, t }) =>
						t('transmittals:transmittals.program.title', { program: params.program.toUpperCase() }),
				},
				children: [
					{
						path: TransmittalPaths.Program.Edit.path,
						element: <WithProgram />,
					} as Route<typeof TransmittalPaths.Program>,
				],
			} as Route<typeof TransmittalPaths.Program>,
		],
	} as Route<typeof TransmittalPaths>,
];

export default TransmittalRoutes;
