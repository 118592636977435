import { Action, Actions, Permission, PermissionProvider } from 'core/types/permissions';
import { createContext } from 'react';

export type PermissionsContextProps = {
	permissions: Permission[];
	refreshPermissions: () => Promise<void>;
	loading: boolean;
};

export const PermissionsContext = createContext<PermissionsContextProps>({
	permissions: [],
	refreshPermissions: () => Promise.resolve(),
	loading: true,
});

export class PermissionsService implements PermissionProvider {
	// Cache permissions for 5 minutes
	protected permissions: Permission[] = [];

	// Cache timeout
	private cacheTimeout = 0;

	// Cache time
	protected cacheTime = 1000 * 60 * 5;

	// Event handlers
	private subscribers: ((permissions: Permission[]) => void)[] = [];

	// Retrieve the permissions for the current user
	// This should return *quickly* so we cache the result
	async getPermissions() {
		// No permissions or expired cache
		if (this.isExpired()) {
			// Implement loading permissions from somewhere and
			// storing them with setPermissions
			this.setPermissions([]);
		}

		return this.permissions;
	}

	// Force refresh the permissions
	async refresh() {
		this.cacheTimeout = 0;
		await this.getPermissions();
	}

	// Set the permissions
	protected setPermissions(permissions: Permission[]) {
		this.permissions = permissions;
		this.subscribers.forEach((fn) => fn(this.permissions));

		// Set cache expiration
		this.cacheTimeout = new Date().getTime() + this.cacheTime;
	}

	// Check if cache is expired
	protected isExpired() {
		return this.cacheTimeout < new Date().getTime();
	}

	// Subscribe to changes
	subscribe(fn: (permissions: Permission[]) => void) {
		this.subscribers.push(fn);
	}

	// Unsubscribe from changes
	unsubscribe(fn: (permissions: Permission[]) => void) {
		this.subscribers = this.subscribers.filter((f) => f !== fn);
	}
}

// Check if the user has the permission, following the same rules as the Backend
// - Check for explicit deny
// - Check for allow all, resource match or all, action match or all
// - If permission is WRITE, allow CREATE and UPDATE
// - If none of the above, deny
// TODO improve with hash map for faster lookup, if needed
export function canAccess(permissions: Permission[], resource: string, action: Action, primaryKey?: string): boolean {
	// Check if the current user has access to a specific resource
	const denied =
		permissions
			.filter((p) => p.type === 'deny' && (p.resource === resource || p.resource === Actions.ALL))
			.filter((p) => p.actions.includes(action) || p.actions.includes(Actions.ALL))
			.filter((p) => !p.primaryKey || p.primaryKey === primaryKey).length > 0;
	if (denied) return false;

	// Allow all, resource match or all, action match or all
	const allowed =
		permissions
			.filter((p) => p.type !== 'deny' && (p.resource === resource || p.resource === Actions.ALL))
			.filter((p) => p.actions.includes(action) || p.actions.includes(Actions.ALL))
			.filter((p) => !p.primaryKey || p.primaryKey === primaryKey).length > 0;
	if (allowed) return true;

	// If permission is WRITE, allow CREATE and UPDATE
	if (action === Actions.WRITE) {
		return canAccess(permissions, resource, Actions.CREATE) || canAccess(permissions, resource, Actions.UPDATE);
	}

	// No permission found, deny
	return false;
}
