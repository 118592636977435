import { appConfig } from 'config';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(ChainedBackend)
	.init({
		fallbackLng: 'en',
		debug: process.env.NODE_ENV === 'development',
		ns: ['core', ...(appConfig.i18n?.defaultNamespaces || [])],
		defaultNS: 'core',
		backend: {
			backends: [
				resourcesToBackend((lang: string, ns: string) => (ns === 'core' ? import(`core/i18n/${lang}.json`) : null)),
				resourcesToBackend(
					(lang: string, ns: string) => import(`modules/${ns.split('/').join('/modules/')}/i18n/${lang}.json`),
				),
			],
		},
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
