import { AlertColor } from '@mui/material';
import { PropsWithChildren } from 'react';
import Dialog from './Dialog';

interface ConfirmDialogProps {
	title: string;
	severity?: AlertColor | 'primary';
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onConfirm?: () => void;
}

function ConfirmDialog({
	title,
	severity = 'primary',
	isOpen,
	setIsOpen,
	onConfirm,
	children,
}: PropsWithChildren<ConfirmDialogProps>) {
	return (
		<Dialog title={title} severity={severity} isOpen={isOpen} setIsOpen={setIsOpen} onConfirm={onConfirm}>
			{children}
		</Dialog>
	);
}

export default ConfirmDialog;
