import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import FleetPaths from 'modules/irp/modules/fleets/routes/paths';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import EditFleetSteps from 'modules/irp/modules/supplements/modules/edit_fleet/components/EditFleetSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { lazy, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import EditFleetPaths from './paths';

const EditFleetDetailsStep = lazy(() => import('./EditFleetDetailsStep'));
const EditFleetVerifyStep = lazy(() => import('./EditFleetVerifyStep'));
const EditFleetSubmitStep = lazy(() => import('./EditFleetSubmitStep'));

function EditFleetIndex() {
	const { supplementKey } = useTypedParams(EditFleetPaths);
	return <Redirect to={EditFleetPaths.Details.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/delete_vehicle');
	return (
		<SupplementBlocker basePath={EditFleetPaths} title={t('irp/supplements/edit_fleet:dialogs.close.title')}>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const EditFleetRoutes = [
	{
		path: EditFleetPaths.path,
		element: (
			<CanAccess resource="irp.supplements.edit_fleet" action={Actions.READ} denied={<NoPermission />}>
				<SupplementPageContainer
					title="irp/supplements/edit_fleet:title"
					breadcrumbs={['irp/supplements/edit_fleet', 'irp/supplements', 'irp/weight_groups', 'irp/vehicles']}
					stepRoutes={[EditFleetPaths.Details.path, EditFleetPaths.Verify.path, EditFleetPaths.Submit.path]}
					closePath={FleetPaths.buildPath({})}
					steps={<EditFleetSteps />}
				>
					<WithBlocker>
						<Outlet />
					</WithBlocker>
				</SupplementPageContainer>
			</CanAccess>
		),
		children: [
			// Index route
			{
				path: EditFleetPaths.path,
				index: true,
				element: <EditFleetIndex />,
			},

			// Steps

			// Details
			{
				path: EditFleetPaths.Details.path,
				element: <Outlet />,
				handle: {
					crumb: ({ t }) => t('irp/supplements/edit_fleet:title'),
				},
				children: [
					{
						index: true,
						path: EditFleetPaths.Details.path,
						element: <EditFleetDetailsStep />,
					} as Route<typeof EditFleetPaths.Details>,
				],
			} as Route<typeof EditFleetPaths.Details>,

			// Verify
			{
				path: EditFleetPaths.Verify.path,
				element: <EditFleetVerifyStep />,
				handle: {
					crumb: ({ t }) => t('irp/supplements/edit_fleet:title'),
				},
			} as Route<typeof EditFleetPaths.Verify>,

			// Submit
			{
				path: EditFleetPaths.Submit.path,
				element: <EditFleetSubmitStep />,
				handle: {
					crumb: ({ t }) => t('irp/supplements/edit_fleet:title'),
				},
			} as Route<typeof EditFleetPaths.Submit>,
		],
	} as Route<typeof EditFleetPaths>,
];

export default EditFleetRoutes;
