import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';

const PaymentsModule: Module = {
	// ID of the module
	id: 'payments',

	// Navigation bar items
	nav: [
		{
			id: 'payments',
			to: RazorPaths.Payments.path,
		},
	],
};

export default PaymentsModule;
