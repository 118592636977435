import { AppConfig, Config as ConfigT } from 'core/types/config';
import { useTranslation } from 'react-i18next';

export const appConfig: AppConfig = {
	i18n: {
		defaultNamespaces: ['data'],
	},
};

export default function Config(): ConfigT {
	const { t } = useTranslation('home');

	return {
		userMenuItems: [
			{ id: 'recent-searches', label: t('navbar.recent-searches') },
			// { id: 'comments', label: t('navbar.comments') },
			'divider',

			{ id: 'training', label: t('navbar.training'), to: 'https://dev.iterisclearfleet.com/Training' },
			{ id: 'ia-dot', label: 'IOWA DOT', to: 'https://iowadot.gov/' },
			{ id: 'contact-us', label: t('navbar.contact-us'), to: 'https://www.iteris.com/contact' },
			{ id: 'help', label: t('navbar.help'), to: '/help' },
			{ id: 'forms', label: t('navbar.forms'), to: 'https://dev.iterisclearfleet.com/Forms' },

			// { id: 'history', label: t('navbar.history') },
			// { id: 'inventory', label: t('navbar.inventory') },
			// { id: 'print-jobs', label: t('navbar.print-jobs') },
			// { id: 'reports', label: t('navbar.reports') },
			// { id: 'transmittals', label: t('navbar.transmittals') },
			// { id: 'user-accounts', label: t('navbar.user-accounts') },
		],
	};
}
