import LookupValue, { LookupValueID } from 'types/LookupValue';
import { Address } from './Address';
import Date from './Date';
import Fleet from './Fleet';
import IrpRegistration, { IrpRegistrationFields } from './IrpRegistration';
import { WeightGroupChangeSupplementDetails } from './WeightGroup';

export enum SupplementType {
	AddVehicle = 'AVE',
	EditVehicle = 'EV',
	DeleteVehicle = 'DEV',
	TransferVehicle = 'AXV',

	AddFleet = 'AFL',
	EditFleet = 'CHF',

	WeightGroupChange = 'CWT',

	NewAccount = 'AAC',
}

export default interface Supplement {
	id: number;
	key: string;
	accountKey: string;
	type: LookupValue;
	status: LookupValue;
	number?: number;
	wip?: number;
	effectiveDate: Date;
	invoiceComments?: string;
	invoiceDelivery?: {
		method: LookupValue | null;
		details?: string;
	};
	physicalDelivery?: {
		method: LookupValue | null;
		details?: string;
		address?: Address;
	};

	details: {
		weightGroupChange?: WeightGroupChangeSupplementDetails;
		newAccount?: {
			irpRegistration: IrpRegistration;
		};
	} | null;

	// Associations
	fleet?: Fleet;
}

export interface SupplementIncludeFields {
	fleet?: true;
}

export interface SupplementFields {
	effectiveDate?: Date | null;
	invoiceComments?: string;
	invoiceDelivery?: {
		method?: LookupValue | null;
		details?: string;
	};
	physicalDelivery?: {
		method?: LookupValue | null;
		details?: string;
	};
}

export interface SupplementActionFields {
	approved?: boolean;
	submitted: boolean;
}

export interface WeightGroupChange {
	id: number;
}

export interface CreateSupplementParams {
	accountKey: string | number;
	fleetKey: string;
	type: string;
	weightGroupChange: WeightGroupChange;
}

export interface FleetFields {
	type?: LookupValueID;
	commodityClass?: LookupValueID;
}

export interface CreateFields {
	weightGroupChange?: {
		weightGroup: {
			id: number;
		};
	};
	addFleet?: {
		irpAccountId: number;
	};
	editFleet?: {
		fleet: {
			id: number;
		};
		fleetFields?: FleetFields;
	};
	newAccount?: {
		carrier: {
			id: number;
		};
		irpRegistration: IrpRegistrationFields;
	};
}

export interface CreateSupplementRequest extends CreateFields {
	type: SupplementType;
}

export interface ListRestrictionsRequest extends CreateFields {
	type: SupplementType;
}
