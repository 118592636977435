import ApiService from 'core/services/api';
import { useEffect, useMemo, useState } from 'react';

interface IAPIService<T> {
	// eslint-disable-next-line prettier/prettier
	new(): T;
}

function useAPIService<T>(Service: IAPIService<T>): T {
	// Hooks
	const service = useMemo(() => new Service(), [Service]);
	return service;
}

function useAPIMethod<T>(method: () => Promise<T>) {
	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [resp, setResp] = useState<T>();

	// Call promise
	useEffect(() => {
		method()
			.then((data) => {
				setResp(data);
			})
			.finally(() => setLoading(false));
	}, [method]);

	return { loading, resp };
}

export function useAPI<T>(Service: IAPIService<T>): T;
export function useAPI<T>(method: () => Promise<T>): { loading: boolean; resp?: T };
export function useAPI<T>(ServiceOrMethod: IAPIService<T> | (() => Promise<T>)): T | { loading: boolean; resp?: T } {
	// Is a Service
	if (ServiceOrMethod.prototype instanceof ApiService) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useAPIService(ServiceOrMethod as IAPIService<T>);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useAPIMethod(ServiceOrMethod as () => Promise<T>);
}
