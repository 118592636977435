import DialogContentText from '@mui/material/DialogContentText';
import Dialog from 'core/components/Dialog';
import EditVehiclePaths from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/paths';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

export default function EditVehicleContainer({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/edit_vehicle');
	const { supplementKey, vehicleKey } = useTypedParams(EditVehiclePaths.Vehicle);

	const isWorkflow = (pathname: string) =>
		pathname.startsWith(EditVehiclePaths.Vehicle.Edit.buildPath({ supplementKey, vehicleKey }));

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (nextLocation.state?.bypass) return false;

		return isWorkflow(currentLocation.pathname) && !isWorkflow(nextLocation.pathname);
	});

	return (
		<>
			{children}

			<Dialog
				title={t('dialogs.close_detail.title')}
				severity="error"
				maxWidth="xs"
				isOpen={blocker.state === 'blocked'}
				setIsOpen={() => blocker.reset && blocker.reset()}
				confirmLabel={t('core:buttons.close')}
				cancelLabel={t('core:buttons.stay')}
				onConfirm={() => blocker.proceed && blocker.proceed()}
			>
				<DialogContentText>{t('dialogs.close_detail.description')}</DialogContentText>
			</Dialog>
		</>
	);
}
