import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import SupplementPageContainer, {
	SupplementContainerContext,
} from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import NewAccountAddVehicleContainer from 'modules/irp/modules/supplements/modules/new_account/components/NewAccountAddVehicleContainer';
import NewAccountSteps from 'modules/irp/modules/supplements/modules/new_account/components/NewAccountSteps';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { WeightGroupProvider } from 'modules/irp/modules/weight_groups/providers/WeightGroupProvider';
import { lazy, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { WeightGroupIncludes, WeightGroupNew } from 'types/WeightGroup';
import RazorPaths from 'modules/razor/paths';
import NewAccountPaths from './paths';

const NewAccountDetailsStep = lazy(() => import('./NewAccountDetailsStep'));

const NewAccountFleetStep = lazy(() => import('./NewAccountFleetStep'));
const NewAccountFleetDetails = lazy(() => import('./NewAccountFleetDetails'));
const NewAccountFleetJurisdictions = lazy(() => import('./NewAccountFleetJurisdictions'));
const NewAccountFleetInfo = lazy(() => import('./NewAccountFleetInfo'));

const NewAccountWeightGroupsStep = lazy(() => import('./NewAccountWeightGroupsStep'));
const NewAccountWeightGroupDetails = lazy(() => import('./NewAccountWeightGroupDetails'));
const NewAccountWeightGroupInfo = lazy(() => import('./NewAccountWeightGroupInfo'));

const NewAccountVehiclesStep = lazy(() => import('./NewAccountVehiclesStep'));
const NewAccountAddVehicleOptions = lazy(() => import('./NewAccountAddVehicleOptions'));
const NewAccountAddVehicleInfo = lazy(() => import('./NewAccountAddVehicleInfo'));
const NewAccountAddVehicleWeightGroup = lazy(() => import('./NewAccountAddVehicleWeightGroup'));
const NewAccountAddVehicleRegistration = lazy(() => import('./NewAccountAddVehicleRegistration'));

const NewAccountDocumentsStep = lazy(() => import('./NewAccountDocumentsStep'));
const NewAccountVerifyStep = lazy(() => import('./NewAccountVerifyStep'));
const NewAccountSubmitStep = lazy(() => import('./NewAccountSubmitStep'));

// View Vehicle Detail
const VehicleDetailsRoute = lazy(() => import('./NewAccountVehiclesView'));
const VehicleDetailsPlate = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPlate'));
const VehicleDetailsCredentials = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsCredentials'),
);
const VehicleDetailsPurchase = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsPurchase'));
const VehicleDetailsDocuments = lazy(() => import('modules/irp/modules/vehicles/components/VehicleDetailsDocuments'));
const VehicleDetailsRegistration = lazy(
	() => import('modules/irp/modules/vehicles/components/VehicleDetailsRegistration'),
);

function WeightGroupContainer({ children }: PropsWithChildren) {
	const { supplementKey } = useTypedParams(NewAccountPaths);
	const value = useMemo(
		() => ({ closePath: NewAccountPaths.WeightGroups.buildPath({ supplementKey }) }),
		[supplementKey],
	);

	return <SupplementContainerContext.Provider value={value}>{children}</SupplementContainerContext.Provider>;
}

function WeightGroupProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(NewAccountPaths.WeightGroups.WeightGroup.Info);
	const include: WeightGroupIncludes = useMemo(() => ({ fleet: true, operatingJurisdictions: true }), []);

	return (
		<WeightGroupProvider weightGroupKey={params.weightGroupKey} include={include}>
			{children}
		</WeightGroupProvider>
	);
}

function NewAccountIndex() {
	const { supplementKey } = useTypedParams(NewAccountPaths);
	return <Redirect to={NewAccountPaths.Registration.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/new_account');
	return (
		<SupplementBlocker basePath={NewAccountPaths} title={t('irp/supplements/new_account:dialogs.close.title')}>
			{children}
		</SupplementBlocker>
	);
}

const FleetRoutes = [
	// Fleet details
	{
		path: NewAccountPaths.Fleet.path,
		element: (
			<WithBlocker>
				<Outlet />
			</WithBlocker>
		),
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
		children: [
			{
				index: true,
				path: NewAccountPaths.Fleet.path,
				element: <NewAccountFleetStep />,
			},

			// Fleet information (read-only)
			{
				path: NewAccountPaths.Fleet.Info.path,
				element: <NewAccountFleetInfo />,
				handle: {
					crumb: ({ t }) => t('core:buttons.view'),
				},
			} as Route<typeof NewAccountPaths.Fleet>,
		],
	} as Route<typeof NewAccountPaths.Fleet>,

	// Fleet details form
	{
		path: NewAccountPaths.Fleet.Details.path,
		element: <NewAccountFleetDetails />,
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
	} as Route<typeof NewAccountPaths.Fleet>,

	// Fleet jurisdictions form
	{
		path: NewAccountPaths.Fleet.Jurisdictions.path,
		element: <NewAccountFleetJurisdictions />,
		handle: {
			crumb: ({ t }) => t('irp/fleets:details.title'),
		},
	} as Route<typeof NewAccountPaths.Fleet>,
];

const WeightGroupRoutes = {
	path: NewAccountPaths.WeightGroups.path,
	element: <Outlet />,
	handle: {
		// TODO where does this click back to?
		crumb: ({ t }) => t('irp/weight_groups:title'),
	},
	children: [
		{
			index: true,
			path: NewAccountPaths.WeightGroups.path,
			element: (
				<WithBlocker>
					<NewAccountWeightGroupsStep />
				</WithBlocker>
			),
		} as Route<typeof NewAccountPaths.WeightGroups>,

		// Weight Group add/edit
		{
			path: NewAccountPaths.WeightGroups.WeightGroup.path,
			element: (
				<WeightGroupContainer>
					<NewAccountWeightGroupDetails />
				</WeightGroupContainer>
			),
			handle: {
				crumb: ({ t, params }) => t(`core:buttons.${params.weightGroupKey === WeightGroupNew ? 'add' : 'edit'}`),
			},
		} as Route<typeof NewAccountPaths.WeightGroups.WeightGroup>,

		// Weight Group view info
		{
			path: NewAccountPaths.WeightGroups.WeightGroup.Info.path,
			element: (
				<WeightGroupProviderFromPath>
					<NewAccountWeightGroupInfo />
				</WeightGroupProviderFromPath>
			),
			handle: {
				crumb: ({ t }) => t('core:buttons.view'),
			},
		} as Route<typeof NewAccountPaths.WeightGroups.WeightGroup.Info>,
	],
} as Route<typeof NewAccountPaths.WeightGroups>;

const VehicleRoutes = {
	path: NewAccountPaths.Vehicles.path,
	element: <Outlet />,
	handle: {
		crumb: ({ t }) => t('irp/vehicles:title'),
	},
	children: [
		{
			index: true,
			path: NewAccountPaths.Vehicles.path,
			element: (
				<WithBlocker>
					<NewAccountVehiclesStep />
				</WithBlocker>
			),
		} as Route<typeof NewAccountPaths.Vehicles>,

		// Vehicle add/edit
		{
			path: NewAccountPaths.Vehicles.Vehicle.path,
			element: (
				<NewAccountAddVehicleContainer>
					<Outlet />
				</NewAccountAddVehicleContainer>
			),
			handle: {
				crumb: ({ t }) => t('irp/vehicles:details.title'),
			},
			children: [
				// Index route
				{
					path: '',
					index: true,
					element: <NewAccountAddVehicleOptions />,
				} as Route<typeof NewAccountPaths.Vehicles.Vehicle>,
				{
					path: NewAccountPaths.Vehicles.Vehicle.Info.path,
					element: <NewAccountAddVehicleInfo />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.WeightGroup.path,
					element: <NewAccountAddVehicleWeightGroup />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.Registration.path,
					element: <NewAccountAddVehicleRegistration />,
				} as Route<typeof NewAccountPaths.Vehicles.Vehicle.Registration>,
			],
		} as Route<typeof NewAccountPaths.Vehicles.Vehicle>,

		// Vehicle View
		{
			path: NewAccountPaths.Vehicles.Vehicle.View.path,
			handle: {
				crumb: ({ t }) => t('core:buttons.view'),
			},
			element: <VehicleDetailsRoute />,
			children: [
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.path,
					index: true,
					element: <VehicleDetailsPlate />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Credentials.path,
					element: <VehicleDetailsCredentials />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Purchase.path,
					element: <VehicleDetailsPurchase />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Documents.path,
					element: <VehicleDetailsDocuments />,
				},
				{
					path: NewAccountPaths.Vehicles.Vehicle.View.Registration.path,
					element: <VehicleDetailsRegistration />,
				} as Route<typeof NewAccountPaths.Vehicles.Vehicle.View.Registration>,
			],
		} as Route<typeof NewAccountPaths.Vehicles.Vehicle.View>,
	],
} as Route<typeof NewAccountPaths.Vehicles>;

// Route component mappings
const NewAccountRoutes = [
	{
		path: NewAccountPaths.path,
		element: (
			<CanAccess resource="irp.supplements.new_account" action={Actions.READ} denied={<NoPermission />}>
				<SupplementPageContainer
					title="irp/supplements/new_account:title"
					breadcrumbs={[
						'irp/supplements/new_account',
						'irp/supplements',
						'irp/fleets',
						'irp/weight_groups',
						'irp/vehicles',
						'accounts',
					]}
					stepRoutes={[
						NewAccountPaths.Registration.path,
						NewAccountPaths.Fleet.path,
						NewAccountPaths.Fleet.Details.path,
						NewAccountPaths.Fleet.Jurisdictions.path,
						NewAccountPaths.WeightGroups.path,
						NewAccountPaths.Vehicles.path,
						NewAccountPaths.Documentation.path,
						NewAccountPaths.Verify.path,
						NewAccountPaths.Submit.path,
					]}
					closePath={RazorPaths.Manage.WorkInProcess.buildPath({})}
					steps={<NewAccountSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('irp/supplements/new_account:title'),
		},
		children: [
			// Index route
			{
				path: NewAccountPaths.path,
				index: true,
				element: <NewAccountIndex />,
			},

			// Registration details
			{
				path: NewAccountPaths.Registration.path,
				element: (
					<WithBlocker>
						<NewAccountDetailsStep />
					</WithBlocker>
				),
			} as Route<typeof NewAccountPaths>,

			...FleetRoutes,
			WeightGroupRoutes,
			VehicleRoutes,

			// Documentation
			{
				path: NewAccountPaths.Documentation.path,
				element: (
					<WithBlocker>
						<NewAccountDocumentsStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:documentation.title'),
				},
			} as Route<typeof NewAccountPaths.Documentation>,

			// Verify
			{
				path: NewAccountPaths.Verify.path,
				element: (
					<WithBlocker>
						<NewAccountVerifyStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:verify.title'),
				},
			} as Route<typeof NewAccountPaths.Verify>,

			// Submit
			{
				path: NewAccountPaths.Submit.path,
				element: (
					<WithBlocker>
						<NewAccountSubmitStep />
					</WithBlocker>
				),
				handle: {
					crumb: ({ t }) => t('irp/supplements:submit.title'),
				},
			} as Route<typeof NewAccountPaths.Submit>,
		],
	} as Route<typeof NewAccountPaths>,
];

export default NewAccountRoutes;
