/* eslint-disable react/jsx-props-no-spreading */
import { AutocompleteProps, CircularProgress, Autocomplete as MuiAutocomplete, TextField, styled } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import FormControl, { FormControlProps } from 'core/components/FormControl';
import { useCallback, useEffect, useState } from 'react';

export interface FormAutoCompleteProps<T> extends FormControlProps {
	value: T | null;
	getOptions: (value: string) => Promise<T[]>;
	debounce?: number;
	onChange: (value: T | null) => void;
	slotProps: FormControlProps['slotProps'] & {
		input?: InputProps;
		autocomplete?: Omit<
			AutocompleteProps<T, false, boolean, false>,
			'onChange' | 'onInputChange' | 'filterOptions' | 'options' | 'value' | 'loading' | 'renderInput'
		> & {
			noOptionsText?: string | ((inputValue: string, debouncing: boolean) => string);
		};
	};
}

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
	'&': {
		backgroundColor: '#f4f6f9',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#e7e9ec',
		transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
	},
	'& .MuiInputBase-root': {
		width: '100%',
		padding: 0,

		'.MuiInputBase-input': {
			padding: theme.spacing(1.25, 1.5),
			transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

			'&.MuiInputBase-inputAdornedStart': {
				paddingLeft: 0,
			},
			'&.Mui-disabled': {
				cursor: 'not-allowed',
			},
		},
	},
})) as typeof MuiAutocomplete;

export default function FormAutoComplete<T>({
	name,
	label,
	helperText,
	value,
	debounce,
	getOptions,
	onChange,
	slotProps,
}: FormAutoCompleteProps<T>) {
	// State
	const [inputValue, setInputValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [debouncing, setDebouncing] = useState(false);
	const [options, setOptions] = useState<T[]>([]);

	const isEqual = slotProps.autocomplete?.isOptionEqualToValue || ((option, v) => option === v);

	const loadOptions = useCallback(() => {
		setLoading(true);
		getOptions(inputValue)
			.then((data) => {
				setOptions(data);

				// If we have a value, try to find and set the option
				if (value) {
					const v = data.find((x) => isEqual(value, x));
					if (v) onChange(v);
				}
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, getOptions]);

	useEffect(() => {
		// Debounce
		setDebouncing(true);

		const t = setTimeout(() => {
			setDebouncing(false);

			if (!inputValue) return;
			loadOptions();
		}, debounce || 0);

		return () => {
			clearTimeout(t);
			setDebouncing(false);
		};
	}, [debounce, inputValue, loadOptions]);

	// Load initial options if value is set
	useEffect(() => {
		if (value) loadOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (newValue: T | null) => {
		onChange(newValue);

		// If field is cleared, clear out options
		if (!newValue) setOptions([]);
	};

	const noOptionsText =
		typeof slotProps.autocomplete?.noOptionsText === 'function'
			? slotProps.autocomplete.noOptionsText(inputValue, debouncing)
			: slotProps.autocomplete?.noOptionsText;

	return (
		<FormControl name={name} label={label} helperText={helperText} slotProps={slotProps}>
			<Autocomplete
				{...slotProps.autocomplete}
				onChange={(_, newValue) => handleChange(newValue)}
				onBlur={() => {
					if (!value) setOptions([]);
				}}
				onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
				filterOptions={(x) => x}
				noOptionsText={noOptionsText}
				options={options}
				value={value}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
						error={slotProps.formControl?.error}
					/>
				)}
			/>
		</FormControl>
	);
}
