import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Breadcrumbs, PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useRouteMatch } from 'core/types/routing';
import CarrierSupplementInfo from 'modules/irp/modules/supplements/components/CarrierSupplementInfo';
import { CarrierProvider } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import RazorPaths from 'modules/razor/paths';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarrierIncludeFields } from 'types/Carrier';

const DEFAULT_CLOSE_PATH = RazorPaths.Manage.WorkInProcess.buildPath({});

interface SupplementPageContainerProps {
	title: string;
	breadcrumbs: string[];
	steps: JSX.Element;
	// Step routes determines if the steps should be displayed
	stepRoutes: string[];
	closePath?: string;
}

export const SupplementContentSkeleton = (
	<Box height={875} maxHeight="70vh" width="100%">
		<Skeleton sx={{ WebkitTransform: 'none' }} width="100%" height="100%" />
	</Box>
);

export const CarrierSupplementInfoSkeleton = (
	<Box height={365} maxHeight="70vh" width="100%">
		<Skeleton sx={{ WebkitTransform: 'none' }} width="100%" height="100%" />
	</Box>
);

type SupplementContainerContextProps = {
	closePath: string;
};

const DefaultSupplementContainerContext: SupplementContainerContextProps = {
	closePath: RazorPaths.Manage.WorkInProcess.buildPath({}),
};

export const SupplementContainerContext = createContext<SupplementContainerContextProps>(
	DefaultSupplementContainerContext,
);

export default function SupplementPageContainer({
	breadcrumbs,
	title,
	steps,
	stepRoutes,
	closePath,
	children,
}: PropsWithChildren<SupplementPageContainerProps>) {
	// Hooks
	const { t } = useTranslation([...breadcrumbs, 'irp/supplements']);
	const { supplement, loading: supplementLoading } = useSupplement();

	useTitle(`${t(title)} - ${t('core:title')}`);

	const isStepRoute = !!useRouteMatch(stepRoutes)?.pattern.path;
	const includes: CarrierIncludeFields = useMemo(() => ({ address: true }), []);
	const value = useMemo(() => ({ closePath: closePath || DEFAULT_CLOSE_PATH }), [closePath]);

	return (
		<CarrierProvider carrierKey={supplement?.accountKey || ''} includes={includes}>
			<SupplementContainerContext.Provider value={value}>
				<PageContainer>
					<Breadcrumbs ns={breadcrumbs} />

					<Grid container spacing={3} mt={2} wrap="wrap">
						{isStepRoute && (
							<Grid sx={{ order: { xs: 2, xl: 1 } }} item xs={12} xl={9}>
								{steps}
							</Grid>
						)}

						<Grid sx={{ order: { xs: 3, xl: 2 } }} item xs={12} xl={9}>
							{supplementLoading ? SupplementContentSkeleton : children}
						</Grid>

						<Grid sx={{ order: { xs: 1, xl: 3 } }} item xs={12} xl={3}>
							<CarrierSupplementInfo supplement={supplement} />
						</Grid>
					</Grid>
				</PageContainer>
			</SupplementContainerContext.Provider>
		</CarrierProvider>
	);
}

export function useSupplementContainer(): SupplementContainerContextProps {
	const context = useContext(SupplementContainerContext);
	if (context === DefaultSupplementContainerContext) {
		throw new Error('useSupplement must be used within a SupplementPageContainer');
	}

	return context;
}
