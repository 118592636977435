import { Info } from '@mui/icons-material';
import { alpha, Box, BoxProps, styled, tablePaginationClasses, Tooltip, useTheme } from '@mui/material';
import { gridClasses, GridValidRowModel, DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { ReactNode } from 'react';

const ODD_OPACITY = 0.1;

export default function DataGrid<T extends GridValidRowModel>() {
	return styled(MuiDataGrid<T>)(({ theme }) => ({
		'&': {
			border: 0,
		},
		// Restore the header border
		[`& .${gridClasses.columnHeaders}`]: {
			borderBottom: `2px solid ${theme.palette.divider}`,
		},
		// Always show the table dividers
		[`& .${gridClasses.columnSeparator}`]: {
			visibility: 'visible',
		},
		[`& .${gridClasses.columnHeader}:last-of-type .${gridClasses.columnSeparator}`]: {
			display: 'none',
		},
		// Cells
		[`& .${gridClasses.cell}`]: {
			borderBottom: 0,
		},
		// Sortable column headers
		[`& .${gridClasses['columnHeader--sortable']} .${gridClasses.columnHeaderTitle}`]: {
			color: theme.palette.primary.main,
		},
		// Pagination icon
		[`& .${tablePaginationClasses.selectIcon}`]: {
			color: theme.palette.primary.main,
		},
		// Restore the body border
		[`& .${gridClasses.virtualScroller}`]: {
			borderBottom: `2px solid ${theme.palette.divider}`,
		},
		// Striped rows:
		// https://mui.com/x/react-data-grid/style/#striped-rows
		'&.striped': {
			[`.${gridClasses.row}`]: {
				'&:hover, &.Mui-hovered, &.even:hover': {
					backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
					'@media (hover: none)': {
						backgroundColor: 'transparent',
					},
				},
				'&.even': {
					backgroundColor: theme.palette.grey[100],
					'&.Mui-selected': {
						backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
						'&:hover, &.Mui-hovered': {
							// Reset on touch devices, it doesn't add specificity
							'@media (hover: none)': {
								backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
							},
						},
					},
				},
			},
		},
	}));
}

export interface DataGridColumnHeaderProps extends Pick<BoxProps, 'sx'> {
	label: string;
	tooltip?: ReactNode;
}

export function DataGridColumnHeader({ label, tooltip, sx }: DataGridColumnHeaderProps) {
	const theme = useTheme();

	return (
		<Box
			display="flex"
			alignItems="center"
			className={gridClasses.columnHeaderTitle}
			fontWeight={500}
			gap={0.5}
			sx={{
				whiteSpace: 'normal',
				wordWrap: 'break-word',
				textOverflow: 'ellipsis',
				lineHeight: 'normal',
				...sx,
			}}
		>
			<Box>{label}</Box>
			{tooltip && (
				<Tooltip title={tooltip} sx={{ color: theme.palette.primary.main }}>
					<Info fontSize="small" />
				</Tooltip>
			)}
		</Box>
	);
}

// { whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: 1.25, textAlign: 'center', width: 112 }
