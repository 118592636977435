import { Search } from '@mui/icons-material';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { CanAccess } from 'core/components';
import StyledDataGrid from 'core/components/DataGrid';
import { useAPI, usePermissions } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import { Actions } from 'core/types/permissions';
import { useFormik } from 'formik';
import CarriersService from 'modules/irp/modules/supplements/api/CarriersService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import AgencyUseOnlyDivider from 'modules/irp/modules/supplements/components//AgencyUseOnlyDivider';
import SupplementApproveAndInvoice from 'modules/irp/modules/supplements/components/SupplementApproveAndInvoice';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import Vehicle from 'modules/irp/modules/vehicles/types/Vehicle';
import RazorPaths from 'modules/razor/paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Address, AddressType, PhysicalDeliveryType } from 'types/Address';
import { CarrierContact } from 'types/Carrier';
import ElectronicDeliveryMethod from 'types/ElectronicDeliveryMethod';
import Program from 'types/Program';
import { SupplementActionFields } from 'types/Supplement';
import * as Yup from 'yup';

export interface SupplementSubmitFormProps<T extends GridValidRowModel> {
	previousPath: string;
	columns: GridColDef<T>[];
	getRows?: () => Promise<T[]>;
	filterRows?: (searchValue: string, rows: T[]) => T[];
	noCredentials?: true;
	showEffectiveMonth?: boolean;
}

export default function SupplementSubmitForm<T extends GridValidRowModel = Vehicle>({
	previousPath,
	columns,
	getRows,
	filterRows,
	noCredentials,
	showEffectiveMonth,
}: SupplementSubmitFormProps<T>) {
	const { t } = useTranslation(['irp/supplements', 'irp/vehicles']);
	const navigate = useNavigate();

	// Params
	const { supplement } = useSupplement();
	const { canAccess } = usePermissions();
	const { clientAddresses } = useClient();
	const { carrier } = useCarrier();

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [rows, setRows] = useState<T[]>([]);
	const [preferredContact, setPreferredContact] = useState<CarrierContact | null>(null);
	const [searchValue, setSearchValue] = useState<string>('');

	// Services
	const supplementsService = useAPI(SupplementsService);
	const carriersService = useAPI(CarriersService);

	// Form
	const formik = useFormik<SupplementActionFields>({
		initialValues: { approved: false, submitted: true },
		validationSchema: Yup.object().shape({ approved: Yup.boolean() }),
		onSubmit: (updateFields) => {
			if (!supplement) return undefined;

			return supplementsService.triggerAction(supplement.key, updateFields).then(({ invoice }) => {
				// Total due, let's go pay it; otherwise, go to manage supplements
				// NOTE: if only submitting and not approving, the invoice will not be created/returned
				//       and the user will be redirected to the manage supplements page
				const redir = (invoice?.totals.due || 0) > 0 ? RazorPaths.Payments : RazorPaths.Manage.Supplements;
				navigate(redir.buildPath({}), { state: { bypass: true } });

				// Return new promise to keep the loading state on the Submit button
				return new Promise(() => {
					/* do nothing */
				});
			});
		},
	});

	// Computed
	const DataGrid = StyledDataGrid<T>();

	let address: Address | null = null;
	switch (supplement?.physicalDelivery?.method?.code) {
		case PhysicalDeliveryType.MailingAddress:
			address = carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Mailing) || null;
			// Default to physical if mailing is not set
			if (!address) address = carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) || null;
			break;
		case PhysicalDeliveryType.BusinessAddress:
			address = carrier?.addresses?.irp.find((a) => a.type?.code === AddressType.Physical) || null;
			break;
		case PhysicalDeliveryType.PickupAddress:
			address = clientAddresses ? clientAddresses[0] : null;
			break;
		case PhysicalDeliveryType.OtherAddress:
			address = supplement.physicalDelivery.address || null;
			break;
		default:
			break;
	}

	useEffect(() => {
		if (!supplement) return;

		const defaultGetRows = async () => {
			if (!supplement) return [];
			const vehicles = supplementsService.listVehicles(supplement.key, { weightGroup: true });
			return vehicles as unknown as T[];
		};

		Promise.all([
			(getRows || defaultGetRows)().then(setRows),
			carriersService.getContact(supplement.accountKey, Program.IRP).then(setPreferredContact),
		]).finally(() => setLoading(false));
	}, [supplementsService, supplement, carriersService, getRows]);

	// Filter rows if provided
	const filteredRows = filterRows ? filterRows(searchValue, rows) : defaultFilterRows(searchValue, rows);

	if (loading) return SupplementContentSkeleton;

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					<Box>
						<Typography variant="h3" gutterBottom>
							{t('submit.title')}
						</Typography>
						<Typography>{t('submit.subtitle')}</Typography>
					</Box>

					<Box display="flex" flexDirection="column" mt={3} gap={2}>
						{showEffectiveMonth && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('data:supplement.effectiveMonth')}
									</Typography>
									<Typography variant="body2">
										{dayjsFromObject(supplement?.effectiveDate)?.format('MMMM YYYY')}
									</Typography>
								</Grid>
							</Grid>
						)}

						<Grid container>
							<Grid item xs={12} sm={3}>
								<Typography variant="subtitle2" gutterBottom>
									{t('data:supplement.invoiceDelivery.method.label')}
								</Typography>
								<Typography variant="body2">{supplement?.invoiceDelivery?.method?.displayName}</Typography>
							</Grid>
							{supplement?.invoiceDelivery?.method?.code !== ElectronicDeliveryMethod.DownloadPdf && (
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{supplement?.invoiceDelivery?.method?.displayName}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceDelivery?.details}</Typography>
								</Grid>
							)}
						</Grid>

						{!noCredentials && (
							<Grid container>
								<Grid item xs={12} sm={3}>
									<Typography variant="subtitle2" gutterBottom>
										{t('data:supplement.physicalDelivery.method.label')}
									</Typography>
									<Typography variant="body2">{supplement?.physicalDelivery?.method?.displayName}</Typography>
								</Grid>
								{supplement?.physicalDelivery?.method?.code !== ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('core:data.fields.address')}
										</Typography>
										{address && (
											<>
												<Typography variant="body2">{address.line1}</Typography>
												<Typography variant="body2">{address.line2}</Typography>
												<Typography variant="body2">
													{address.city}, {address.state?.code} {address.postalCode}, {address.country}
												</Typography>
											</>
										)}
									</Grid>
								)}
								{supplement?.physicalDelivery?.method?.code === ElectronicDeliveryMethod.Email && (
									<Grid item xs={12} sm={3}>
										<Typography variant="subtitle2" gutterBottom>
											{t('core:data.fields.email')}
										</Typography>
										{preferredContact && <Typography variant="body2">{preferredContact.contact.email}</Typography>}
									</Grid>
								)}
							</Grid>
						)}

						<CanAccess resource="irp.supplements.fields" action={Actions.WRITE} primaryKey="InvoiceComments">
							<Grid container columnGap={8}>
								<Grid item xs={12} sm={6}>
									<Typography variant="subtitle2" gutterBottom>
										{t('data:supplement.invoice_comments')}
									</Typography>
									<Typography variant="body2">{supplement?.invoiceComments || <span>&mdash;</span>}</Typography>
								</Grid>
							</Grid>
						</CanAccess>
					</Box>

					<Box display="flex" flexDirection="row" justifyContent="space-between" my={4}>
						<Typography variant="h5">{t('irp/vehicles:details.title')}</Typography>
						<Input
							startAdornment={<Search color="primary" />}
							placeholder={`${t('core:buttons.search')}...`}
							value={searchValue}
							onChange={(e) => setSearchValue(e.currentTarget.value)}
						/>
					</Box>

					<DataGrid className="striped" columns={columns} rows={filteredRows} disableRowSelectionOnClick />

					<Box mt={2}>
						<AgencyUseOnlyDivider />
						<SupplementApproveAndInvoice
							value={formik.values.approved || false}
							onChange={(v) => formik.setFieldValue('approved', v)}
						/>
					</Box>
				</CardContent>
			</Card>
			<SupplementStepFooter
				nextLabel={t('core:buttons.submit')}
				onNext={formik.submitForm}
				nextDisabled={!canAccess('irp.supplements', 'submit')}
				previousPath={previousPath}
			/>
		</Box>
	);
}

function defaultFilterRows<T extends GridValidRowModel = Vehicle>(searchValue: string, rows: T[]) {
	return rows.filter((vehicle) => {
		const { vin, title, unitNumber } = vehicle;
		const search = searchValue.toLowerCase();
		return (
			vin.toLowerCase().includes(search) ||
			title?.number?.toLowerCase().includes(search) ||
			unitNumber.toLowerCase().includes(search)
		);
	});
}
