import EditFleetPaths from 'modules/irp/modules/supplements/modules/edit_fleet/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps, { SupplementStepProps } from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function EditFleetSteps() {
	const { t } = useTranslation(['irp/supplements/edit_fleet', 'irp/supplements']);

	const { supplementKey } = useTypedParams(EditFleetPaths);

	const steps: SupplementStepProps['steps'] = [
		{
			label: t('details.step'),
			route: EditFleetPaths.Details.buildPath({ supplementKey }),
		},
		{ label: t('irp/supplements:verify.step'), route: EditFleetPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:submit.step'), route: EditFleetPaths.Submit.buildPath({ supplementKey }) },
	];

	return <SupplementSteps steps={steps} />;
}
