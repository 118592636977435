import { NotFound } from 'core/components';
import { useAPI, useToast } from 'core/hooks';
import CarrierService from 'modules/irp/modules/supplements/api/CarriersService';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carrier, { CarrierIncludeFields } from 'types/Carrier';

type CarrierContextProps = {
	loading: boolean;
	carrier: Carrier | null;
};

const DefaultCarrierContext: CarrierContextProps = {
	loading: true,
	carrier: null,
};

const CarrierContext = createContext<CarrierContextProps>(DefaultCarrierContext);

export interface CarrierProviderProps {
	carrierKey: string;
	includes?: CarrierIncludeFields;
}

export function CarrierProvider({ carrierKey, includes, children }: PropsWithChildren<CarrierProviderProps>) {
	const { t } = useTranslation(['irp/supplements']);
	const { openToast } = useToast();

	// Services
	const carriersService = useAPI(CarrierService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [carrier, setCarrier] = useState<Carrier | null>(null);

	// Load data once
	useEffect(() => {
		if (carrierKey === '') return;

		setLoading(true);
		carriersService
			.getByAccountKey(carrierKey, includes, { quiet: true })
			.then(setCarrier)
			.catch(() => setLoading(false))
			.finally(() => setLoading(false));
	}, [carrierKey, carriersService, includes]);

	const value = useMemo<CarrierContextProps>(() => {
		return { loading, carrier };
	}, [loading, carrier]);

	if (!loading && !carrier) {
		openToast({
			id: `irp/supplements/add_vehicle/info`,
			message: t('errors.carrier.notFound'),
			severity: 'error',
		});

		return <NotFound />;
	}

	return <CarrierContext.Provider value={value}>{children}</CarrierContext.Provider>;
}

export function useCarrier(): CarrierContextProps {
	const context = useContext(CarrierContext);
	if (context === DefaultCarrierContext) {
		throw new Error('useCarrier must be used within a CarrierProvider');
	}

	return context;
}
