import { PermissionsService } from 'core/services/permissions';
import { Actions, Permission, PermissionProvider } from 'core/types/permissions';
import CVOPermissionsService from 'modules/home/services/PermissionsService';

// This service shows how to implement the permissions service
// with a mock set of permissions. In a real application, you
// would probably want to fetch the permissions from the server
// and cache them in the service using the setPermissions method.
class ExamplePermissionsService extends PermissionsService implements PermissionProvider {
	// Retrieve the permissions for the current user
	// This should return *quickly* so we cache the result
	async getPermissions() {
		const permissionsService = new CVOPermissionsService();

		// No permissions or expired cache
		if (this.isExpired()) {
			try {
				const permissions = await permissionsService.get();
				this.setPermissions(permissions);
			} catch (e) {
				// Error, no permissions granted
				return this.permissions;
			}
		}

		// Stub permissions, replace with real permissions
		this.permissions.push({ resource: 'history', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.vehicles', actions: [Actions.READ] });
		// TODO move to permission service
		this.permissions.push({ resource: 'irp.supplements.delete_vehicle', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.registrations', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.fleets', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.weight-groups', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.documents', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.plate-return', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.error-corrections', actions: [Actions.READ] });
		this.permissions.push({ resource: 'irp.wip', actions: [Actions.READ] });

		return this.permissions;
	}

	// Example method to change permissions
	async setPermissions(permissions: Permission[]) {
		super.setPermissions(permissions);
	}
}

export default new ExamplePermissionsService();
