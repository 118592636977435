import { Link, Breadcrumbs as MaterialBreadcrumbs, Typography, TypographyOwnProps } from '@mui/material';
import { Params, Route } from 'core/types/routing';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useMatches } from 'react-router-dom';

type match = Pick<Route<unknown>, 'handle'> & {
	pathname: string;
	params: Params<unknown>;
};

interface BreadcrumbsProps {
	// Pass in the translation namespace(s) for useTranslation
	// so that we are using correctly namespaced translations
	ns?: string | string[];

	// Typography variant
	variant?: TypographyOwnProps['variant'];
}

export default function Breadcrumbs({ ns, variant }: BreadcrumbsProps) {
	const matches = useMatches();
	const { t } = useTranslation(ns);

	const crumbs = (matches as match[]).filter((match) => !!match.handle && !!match.handle.crumb);

	return (
		<MaterialBreadcrumbs sx={{ marginBottom: 2 }}>
			{crumbs
				.filter((c) => !!c.handle && !!c.handle.crumb)
				.map((match, idx) => {
					if (!match || !match.handle || !match.handle.crumb) return null;

					const isLast = idx === crumbs.length - 1;
					const { pathname: path, params } = match;
					const title = match.handle.crumb({ path, params, t });

					return isLast ? (
						<Typography key={match.pathname} variant={variant || 'h3'}>
							{title}
						</Typography>
					) : (
						<Link key={path} to={path} underline="hover" component={RouterLink}>
							<Typography color="primary" component="span" variant={variant || 'h3'}>
								{title}
							</Typography>
						</Link>
					);
				})}
		</MaterialBreadcrumbs>
	);
}
