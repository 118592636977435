import { TFunction } from 'i18next';
import { matchPath, useLocation } from 'react-router-dom';

export type Params<T> = {
	[p in keyof T]: string | number;
};

type RouteParams<T> = T extends {
	getTypedParams: (params: Record<string, string | undefined>) => infer RT;
}
	? RT
	: object;

export type Route<T extends Params<T> | unknown> = {
	// Path to the route
	path: string;

	// Child routes
	children?: Route<unknown>[];

	// Data to attach to the route handler
	handle?: {
		// Special breadcrumb function to return the breadcrumb text
		crumb?: (p: { t: TFunction; path: string; params: RouteParams<T> }) => string;
	};
};

export function useRouteMatch(patterns: readonly string[]) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const possibleMatch = matchPath(patterns[i], pathname);
		if (possibleMatch !== null) return possibleMatch;
	}

	return null;
}
