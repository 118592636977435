import { Typography } from '@mui/material';
import { PageContainer } from 'core/components';
import { useTitle } from 'core/hooks';
import { useTranslation } from 'react-i18next';

export default function Home() {
	const { t } = useTranslation('home');

	useTitle(`${t('home:title')} - ${t('core:title')}`);

	return (
		<PageContainer>
			<Typography variant="h2">{t('core:title')}</Typography>
		</PageContainer>
	);
}
