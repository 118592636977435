import { usePermissions } from 'core/hooks/permissions';
import { Action } from 'core/types/permissions';
import { PropsWithChildren } from 'react';

export interface CanAccessProps {
	// Resource name to check permission for
	// ex. 'docs', 'docs.specific-page'
	resource: string;

	// Action to check permission for
	// ex. 'read', 'write', 'delete'
	action: Action;

	// Primary key to check permission for
	// ex. Account ID, Field name, etc.
	primaryKey?: string;

	// Optional component to render if user does not have access
	denied?: JSX.Element;
}

export default function CanAccess({
	children,
	resource,
	action,
	primaryKey,
	denied,
}: PropsWithChildren<CanAccessProps>): JSX.Element | null {
	const { canAccess } = usePermissions();

	// No permission
	if (!canAccess(resource, action, primaryKey)) return denied || null;

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
