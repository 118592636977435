import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import TransmittalRoutes from './routes';

const TransmittalsModule: Module = {
	// ID of the module
	id: 'transmittals',

	// Navigation bar items
	nav: [], // Empty to show module nav item without dropdown

	// Permissions
	permission: { resource: 'transmittals', action: Actions.READ },

	// Routes
	routes: TransmittalRoutes,
};

export default TransmittalsModule;
