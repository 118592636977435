import { number, route } from 'react-router-typesafe-routes/dom';

const FleetPaths = route(
	'irp/fleets',
	{
		searchParams: {
			registrationYear: number(),
		},
	},
	{
		Fleet: route(
			':key',
			{},
			{
				Edit: route('edit'),
			},
		),
	},
);

export default FleetPaths;
