import { styled } from '@mui/material';
import { animationDuration, pieceSize, pieceSpacing } from './config';

export interface PieceProps {
	face: 'front' | 'left' | 'right' | 'up' | 'down';
	col: 'left' | 'center' | 'right';
	row: 'top' | 'center' | 'bottom';
	color: 'light' | 'main' | 'dark';
}

// x or y position on the cube, left to right, top to bottom
type PiecePos = 1 | 2 | 3;

// Individual piece of the cube
export const Piece = styled('div', {
	shouldForwardProp: (prop) => ['face', 'col', 'row', 'color'].indexOf(prop as string) === -1,
})<PieceProps>(({ face, col, row, color, theme }) => {
	// Translate the row/column position to a number
	const pos = (p: PieceProps['row'] | PieceProps['col']) => {
		if (p === 'top' || p === 'left') return 1;
		if (p === 'center') return 2;
		if (p === 'bottom' || p === 'right') return 3;
		return 2; // default to center, satisfies TS
	};

	// Calculate the top/left property for each row/column, in pixels
	const spacing = (p: PiecePos) => pieceSpacing + (p - 1) * (pieceSize + pieceSpacing);

	// Calculate transform origin relative to the center of the cube
	const transformOrigin = (x: PiecePos, y: PiecePos) => {
		return (
			`${pieceSize / 2 + -1 * (x - 2) * (pieceSize + pieceSpacing)}px` +
			` ${pieceSize / 2 + -1 * (y - 2) * (pieceSize + pieceSpacing)}px` +
			` ${-1 * (pieceSize / 2 + pieceSize + pieceSpacing)}px`
		);
	};

	// Face transforms for each face
	const faceTransforms: Record<PieceProps['face'], string> = {
		front: 'rotateY(0deg)',
		left: 'rotateY(-90deg)',
		right: 'rotateY(90deg)',
		up: 'rotateX(90deg)',
		down: 'rotateX(-90deg)',
	};

	// Animation names by face, row, and column
	const animationNames: Record<
		PieceProps['face'],
		Partial<Record<PieceProps['row'], Partial<Record<PieceProps['col'], string>>>>
	> = {
		front: {
			top: {
				left: 'step-3-front-to-down',
				center: 'step-1-front-to-up',
			},
			center: {
				left: 'step-2-front-to-left',
				center: 'step-1-front-to-up',
				right: 'step-2-front-to-left',
			},
			bottom: {
				left: 'step-3-front-to-down',
				center: 'step-1-front-to-up',
				right: 'step-4-front-to-right',
			},
		},
		left: {
			bottom: {
				left: 'step-4-left-to-front',
				center: 'step-4-left-to-front',
				right: 'step-4-left-to-front',
			},
		},
		right: {
			center: {
				left: 'step-2-3-right-to-front-to-down',
				center: 'step-2-right-to-front',
				right: 'step-2-right-to-front',
			},
		},
		up: {
			top: {
				left: 'step-3-up-to-front',
			},
			center: {
				left: 'step-3-up-to-front',
			},
			bottom: {
				left: 'step-3-4-up-to-front-to-right',
			},
		},
		down: {
			top: {
				center: 'step-1-down-to-front',
			},
			center: {
				center: 'step-2-front-to-left',
			},
			bottom: {
				center: 'step-1-4-down-to-front-to-right',
			},
		},
	};

	return {
		display: 'inline-block',
		width: pieceSize,
		height: pieceSize,
		position: 'absolute',
		backfaceVisibility: 'hidden',
		animationDuration,
		animationIterationCount: 'infinite',

		// Dynamic styles
		backgroundColor: theme.palette.primary[color],
		top: `${spacing(pos(row))}px`,
		left: `${spacing(pos(col))}px`,
		transformOrigin: transformOrigin(pos(col), pos(row)),
		transform: faceTransforms[face],
		animationName: (animationNames[face][row] || {})[col],

		// Animations
		'@keyframes step-1-front-to-up': {
			'0%': { transform: 'rotateX(0deg)' },
			'25%, 100%': { transform: 'rotateX(90deg)' },
		},
		'@keyframes step-1-down-to-front': {
			'0%': { transform: 'rotateX(-90deg)' },
			'25%, 100%': { transform: 'rotateX(0deg)' },
		},
		'@keyframes step-2-front-to-left': {
			'25%': { transform: 'rotateY(0deg)' },
			'50%, 100%': { transform: 'rotateY(-90deg)' },
		},
		'@keyframes step-2-right-to-front': {
			'25%': { transform: 'rotateY(90deg)' },
			'50%, 100%': { transform: 'rotateY(0deg)' },
		},
		'@keyframes step-3-front-to-down': {
			'50%': { transform: 'rotateX(0deg)' },
			'75%, 100%': { transform: 'rotateX(-90deg)' },
		},
		'@keyframes step-2-3-right-to-front-to-down': {
			'25%': { transform: 'rotateY(90deg)' },
			'50%': { transform: 'rotateX(0deg)' },
			'75%, 100%': { transform: 'rotateX(-90deg)' },
		},
		'@keyframes step-3-up-to-front': {
			'50%': { transform: 'rotateX(90deg)' },
			'75%, 100%': { transform: 'rotateX(0deg)' },
		},
		'@keyframes step-4-front-to-right': {
			'75%': { transform: 'rotateY(0deg)' },
			'100%': { transform: 'rotateY(90deg)' },
		},
		'@keyframes step-1-4-down-to-front-to-right': {
			'0%': { transform: 'rotateX(-90deg)' },
			'25%': { transform: 'rotateX(0deg)' },
			'75%': { transform: 'rotateY(0deg)' },
			'100%': { transform: 'rotateY(90deg)' },
		},
		'@keyframes step-3-4-up-to-front-to-right': {
			'50%': { transform: 'rotateX(90deg)' },
			'75%': { transform: 'rotateY(0deg)' },
			'100%': { transform: 'rotateY(90deg)' },
		},
		'@keyframes step-4-left-to-front': {
			'75%': { transform: 'rotateY(-90deg)' },
			'100%': { transform: 'rotateY(0deg)' },
		},
	};
});
