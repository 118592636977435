import { useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useTitle(title: string, prevailOnUnmount = false) {
	const defaultTitle = useRef(document.title);

	// Set title
	useEffect(() => {
		document.title = title;
	}, [title]);

	// Unmount, reset
	useEffect(
		() => () => {
			if (!prevailOnUnmount) {
				document.title = defaultTitle.current;
			}
		},
		[prevailOnUnmount],
	);
}
