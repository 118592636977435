import { Module } from 'core/types/module';
import RazorPaths from 'modules/razor/paths';

const RefundsModule: Module = {
	// ID of the module
	id: 'refunds',

	// Navigation bar items
	nav: [
		{
			id: 'refundqueue',
			to: RazorPaths.RefundQueue.path,
		},
	],
};

export default RefundsModule;
