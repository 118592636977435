import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface RedirectProps {
	to: string;
	replace?: boolean;
}

export default function Redirect({ to, replace }: RedirectProps) {
	const navigate = useNavigate();

	useEffect(() => {
		// External URLs
		if (to.indexOf('http') === 0) {
			if (replace) {
				window.location.replace(to);
				return;
			}

			window.location.href = to;
			return;
		}

		navigate(to, { replace });
	});

	return null;
}
