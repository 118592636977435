import { route, string } from 'react-router-typesafe-routes/dom';

const AccountPaths = route(
	'accounts',
	{},
	{
		Create: route('create', {
			searchParams: {
				userKey: string().defined(),
				accountKey: string(),
			},
		}),
	},
);

export default AccountPaths;
