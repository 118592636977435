import { User } from 'oidc-client-ts';

// eslint-disable-next-line import/prefer-default-export
export function getUser() {
	if (!process.env.REACT_APP_AUTH_URL) throw new Error('AUTH_URL is not defined, cannot configure OIDC');
	if (!process.env.REACT_APP_CLIENT_ID) throw new Error('CLIENT_ID is not defined, cannot configure OIDC');

	const oidcStorage = localStorage.getItem(
		`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_CLIENT_ID}`,
	);
	if (!oidcStorage) {
		return null;
	}

	return User.fromStorageString(oidcStorage);
}
