import { number, route, types } from 'react-router-typesafe-routes/dom';

const WITH_ID = route('', { params: { id: number() } });

const HistoryPaths = route(
	'history',
	{},
	{
		Account: route('account/:id', types(WITH_ID)),
		Fleet: route('fleet/:id', types(WITH_ID)),
		IFTA: route('ifta/:id', types(WITH_ID)),
		Vehicle: route('vehicle/:id', types(WITH_ID)),
		WeightGroup: route('weight-group/:id', types(WITH_ID)),
	},
);

export const HistoryDetailPaths = {
	Account: HistoryPaths.Account,
	Fleet: HistoryPaths.Fleet,
	IFTA: HistoryPaths.IFTA,
	Vehicle: HistoryPaths.Vehicle,
	'Weight group': HistoryPaths.WeightGroup,
};

export default HistoryPaths;
