import { DialogContentText, Grid, InputLabel, Typography } from '@mui/material';
import Dialog, { DialogProps } from 'core/components/Dialog';
import Vehicle from 'modules/irp/modules/vehicles/types/Vehicle';
import { useTranslation } from 'react-i18next';

export interface RemoveVehicleDialogProps extends Pick<DialogProps, 'isOpen' | 'setIsOpen' | 'onConfirm'> {
	vehicle: Vehicle | null;
}

export default function RemoveVehicleDialog({ vehicle, isOpen, setIsOpen, onConfirm }: RemoveVehicleDialogProps) {
	const { t } = useTranslation('irp/supplements');
	if (!vehicle) return null;

	return (
		<Dialog
			title={t('dialogs.remove_vehicle.title')}
			severity="error"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			onConfirm={onConfirm}
		>
			<DialogContentText>{t('dialogs.remove_vehicle.body')}</DialogContentText>

			<Grid container columnSpacing={4} mt={2}>
				<Grid item xs={12} sm>
					<InputLabel sx={{ mb: 1 }}>{t('data:vehicle.vin')}</InputLabel>
					<Typography variant="body2">{vehicle.vin}</Typography>
				</Grid>
				<Grid item xs={12} sm>
					<InputLabel sx={{ mb: 1 }}>{t('data:vehicle.unitNumber')}</InputLabel>
					<Typography variant="body2">{vehicle.unitNumber}</Typography>
				</Grid>
				<Grid item xs={12} sm>
					<InputLabel sx={{ mb: 1 }}>{t('data:vehicle.title.number')}</InputLabel>
					<Typography variant="body2">{vehicle.title.number}</Typography>
				</Grid>
			</Grid>
		</Dialog>
	);
}
