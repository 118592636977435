import { Info } from '@mui/icons-material';
import { Box, Tooltip, styled, useTheme } from '@mui/material';
import MuiFormControl, { FormControlProps as MuiFormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiInputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { PropsWithChildren } from 'react';

export interface FormControlProps {
	name: string;
	label: string;
	helperText?: string;
	slotProps?: {
		formControl?: MuiFormControlProps;
		inputLabel?: InputLabelProps;
		label?: {
			component?: React.ReactNode;
		};
	};
	tooltip?: string | React.ReactNode;
}

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
	'&': {
		position: 'static',
		marginBottom: theme.spacing(1),
	},
}));

export default function FormControl({
	name,
	label,
	helperText,
	slotProps,
	tooltip,
	children,
}: PropsWithChildren<FormControlProps>) {
	const theme = useTheme();

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<MuiFormControl {...slotProps?.formControl}>
			<InputLabel
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...slotProps?.inputLabel}
				htmlFor={name}
				sx={{
					transform: 'none',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: 1,
					whiteSpace: 'normal',
					...slotProps?.inputLabel?.sx,
				}}
				shrink
			>
				<Box component="span" display="flex" alignItems="center" gap={1} whiteSpace="normal">
					{label}
					{tooltip && (
						<Tooltip title={tooltip} color="primary">
							<Info fontSize="small" />
						</Tooltip>
					)}
				</Box>
				{slotProps?.label?.component}
			</InputLabel>
			{children}
			{helperText && (
				<FormHelperText
					sx={{
						color: slotProps?.formControl?.color ? theme.palette[slotProps?.formControl?.color].main : undefined,
					}}
					error={slotProps?.formControl?.error}
				>
					{helperText}
				</FormHelperText>
			)}
		</MuiFormControl>
	);
}
