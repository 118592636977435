import { Route } from 'core/types/routing';
import Help from './Help';
import Home from './Home';

// Route component mappings
const HomeRoutes = [
	{
		path: '/',
		element: <Home />,
	} as Route<void>,
	{
		path: '/help',
		element: <Help />,
		handle: {
			crumb: () => 'Help',
		},
	} as Route<void>,
];

export default HomeRoutes;
