import * as Yup from 'yup';

export default interface LookupValue<T = string> {
	id: number;
	code: T | '';
	displayName: string;
}

export type LookupValueID = Pick<LookupValue, 'id'>;

export const LookupValueValidationSchema = {
	id: Yup.number().required(),
	code: Yup.string(),
	displayName: Yup.string(),
};

export function NewLookupValue<T = string>(): LookupValue<T> {
	return {
		id: 0,
		code: '',
		displayName: '',
	};
}

export function getLookupValueByCode(values: LookupValue[], code: string) {
	return values.find((v) => v.code === code);
}

export type DocumentTypeSchema = {
	code: string;
	displayName: string;
	id: string;
};

export type DocumentSchema = Record<string, Yup.ObjectSchema<DocumentTypeSchema>>;
