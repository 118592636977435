import { Loader } from 'core/components';
import { openToast } from 'core/services/toast';
import AddFleetPaths from 'modules/irp/modules/supplements/modules/add_fleet/routes/paths';
import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import EditFleetPaths from 'modules/irp/modules/supplements/modules/edit_fleet/routes/paths';
import EditVehiclePaths from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/paths';
import NewAccountPaths from 'modules/irp/modules/supplements/modules/new_account/routes/paths';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import WeightGroupChangePaths from 'modules/irp/modules/supplements/modules/weight_group_change/routes/paths';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import RazorPaths from 'modules/razor/paths';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SupplementType } from 'types/Supplement';

export default function SupplementRouter() {
	const { supplement } = useSupplement();
	const navigate = useNavigate();
	const { t } = useTranslation(['irp/supplements']);

	// Redirect based on supplement type
	useEffect(() => {
		if (!supplement) return;
		const { key: supplementKey, accountKey } = supplement;

		// Redirect based on supplement type
		switch (supplement?.type.code) {
			// Add vehicle
			case SupplementType.AddVehicle:
				navigate(AddVehiclePaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Transfer vehicle
			case SupplementType.TransferVehicle:
				navigate(TransferVehiclePaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Edit vehicle
			case SupplementType.EditVehicle:
				navigate(EditVehiclePaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Delete vehicle
			case SupplementType.DeleteVehicle:
				navigate(DeleteVehiclePaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Weight Group Change
			case SupplementType.WeightGroupChange:
				navigate(WeightGroupChangePaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Add Fleet
			case SupplementType.AddFleet:
				navigate(AddFleetPaths.buildPath({ supplementKey }), { replace: true });
				break;
			// Edit Fleet
			case SupplementType.EditFleet:
				navigate(EditFleetPaths.buildPath({ supplementKey }), { replace: true });
				break;
			// New Account
			case SupplementType.NewAccount:
				navigate(NewAccountPaths.buildPath({ supplementKey }), { replace: true });
				break;
			default:
				openToast({
					id: `redirect-dialog:${supplementKey}`,
					message: t('irp/supplements:dialogs.redirect.body'),
					severity: 'success',
				});

				navigate(
					`${RazorPaths.Manage.Supplements.buildPath({})}?${RazorPaths.Manage.Supplements.buildSearch({
						supplementKeyId: supplementKey,
						accountKeyId: accountKey,
					})}`,
				);
		}
	}, [supplement, navigate, t]);

	return <Loader sx={{ flex: 1 }} />;
}
