// Basic permission actions, might be extended in the future
// to include more specific actions like 'export' or 'download'
export enum Actions {
	CREATE = 'create',
	READ = 'read',
	WRITE = 'write',
	UPDATE = 'update',
	DELETE = 'delete',
	ALL = '*',
}

export type Action = Actions | string;

// RBAC permission definition
// A permission is an object that represents a section of the
// app. It is defined by a resource (usually a noun) and one
// or more actions (usually a verb).
//
// The permissions system uses the principle of least privilege. This
// means that by default, a user has no permissions. This is also
// known as an "implicit deny". Permissions are additive, each
// permission granting access to a subset of the application.
//
// Here are a few examples of permissions:
//
// Allow everything:
// { action: '*', resource: '*' }
//
// Allow read actions on all resources:
// { action: 'read', resource: '*' }
//
// Allow all actions except delete on accounts:
// { action: ['read', 'create', 'update'], resource: 'accounts' }
//
// Deny write actions on account addresses:
// { type: 'deny', action: ['write'], resource: 'accounts.address', }: deny to change the address of an account
//
// NOTE: The order of permissions does not matter. Once a permission
//       grants access to an action on a resource, we grant access
//       to it - unless there is an explicit deny. Deny permissions
//       take precedence over allow permissions, regardless of the
//       order they are defined in.
export type Permission = {
	type?: 'deny';
	resource: string;
	actions: Action[];
	primaryKey?: string;
};

// A role is a collection of permissions
export type Role = Permission[];

// A permission provider is a service that provides permissions
// for the current user. It is used by the RBAC service to
// determine if a user has access to a specific resource.
export interface PermissionProvider {
	getPermissions: () => Promise<Permission[]>;
	refresh(): Promise<void>;
}
