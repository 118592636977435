import { Module } from 'core/types/module';
import { Actions } from 'core/types/permissions';
import RazorPaths from 'modules/razor/paths';
import FleetPaths from './modules/fleets/routes/paths';
import SupplementPaths from './modules/supplements/routes/paths';
import WeightGroupPaths from './modules/weight_groups/routes/paths';
import IrpRoutes from './routes';

const IRPModule: Module = {
	// ID of the module
	id: 'irp',

	// Navigation bar items
	nav: [
		{
			id: 'wip',
			to: RazorPaths.Manage.WorkInProcess.path,
			permission: { resource: 'irp.wip', action: Actions.READ },
		},
		{
			id: 'supplements',
			to: SupplementPaths.path,
			permission: { resource: 'irp.supplements', action: Actions.READ },
		},
		{
			id: 'registrations',
			to: RazorPaths.Manage.Credentials.path,
			permission: { resource: 'irp.registrations', action: Actions.READ },
		},
		{
			id: 'vehicles',
			to: RazorPaths.Manage.Vehicles.path,
			permission: { resource: 'irp.vehicles', action: Actions.READ },
		},
		{
			id: 'fleets',
			to: FleetPaths.path,
			permission: { resource: 'irp.fleets', action: Actions.READ },
		},
		{
			id: 'weight-groups',
			to: WeightGroupPaths.path,
			permission: { resource: 'irp.weight-groups', action: Actions.READ },
		},
		{
			id: 'documents',
			to: RazorPaths.Manage.Documents.path,
			permission: { resource: 'irp.documents', action: Actions.READ },
		},
		{
			id: 'plate-return',
			to: RazorPaths.Inventory.PlateReturn.path,
			permission: { resource: 'irp.plate-return', action: Actions.READ },
		},
		{
			id: 'error-corrections',
			to: RazorPaths.ErrorCorrections.path,
			permission: { resource: 'irp.error-corrections', action: Actions.READ },
		},
	],

	// Permission
	permission: { resource: 'irp', action: Actions.READ },

	routes: IrpRoutes,
};

export default IRPModule;
