import NoPermission from 'core/components/NoPermission';
import { useAPI } from 'core/hooks';
import { ApiError } from 'core/services/api';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, {
	NewVehicle,
	VehicleFields,
	VehicleIncludeFields,
	VehicleNew,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';

type VehicleContextProps = {
	loading: boolean;
	fields: VehicleFields | null;
	setFields: (fields: VehicleFields) => void;
	vehicle: Vehicle | null;
};

const DefaultVehicleContext: VehicleContextProps = {
	loading: true,
	vehicle: null,
	fields: null,
	setFields: () => undefined,
};

const VehicleContext = createContext<VehicleContextProps>(DefaultVehicleContext);

export interface VehicleProviderProps {
	vehicleKey: string;
	includes?: VehicleIncludeFields;
}

export function VehicleProvider({ vehicleKey, includes, children }: PropsWithChildren<VehicleProviderProps>) {
	const vehiclesService = useAPI(VehiclesService);

	// State
	const [loading, setLoading] = useState<boolean>(vehicleKey !== VehicleNew);
	const [fields, setFields] = useState<VehicleFields | null>(
		vehicleKey === VehicleNew ? NewVehicle({ withPurchase: true, withPlate: true }) : null,
	);
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		// Load vehicle once
		if (!vehicleKey || fields !== null) return;

		// New vehicle
		if (vehicleKey === VehicleNew) return;

		setLoading(true);
		vehiclesService
			.getByKey(vehicleKey, includes)
			.then((resp) => {
				setFields(resp);
				setVehicle(resp);
			})
			.catch((err) => {
				if (err instanceof ApiError) {
					setError(err);
					return;
				}

				throw err;
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicleKey]);

	const value = useMemo<VehicleContextProps>(() => {
		return { loading, fields, setFields, vehicle, setVehicle };
	}, [loading, fields, vehicle]);

	// If there is an error, display it
	if (error) return <NoPermission />;

	return <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>;
}

export function useVehicle(): VehicleContextProps {
	const context = useContext(VehicleContext);
	if (context === DefaultVehicleContext) {
		throw new Error('useVehicle must be used within a VehicleProvider');
	}

	return context;
}
