import { toastAction } from 'core/providers/ToastProvider';
import { setOpenToastFunction } from 'core/services/toast';
import { ToastProps } from 'core/types/toast';
import { useSnackbar } from 'notistack';
import { createContext, useCallback } from 'react';

export interface ToastContextProps {
	openToast: (props: ToastProps) => void;
}

export const ToastContext = createContext<ToastContextProps>({
	openToast: () => {
		return null;
	},
});

export function useToast() {
	const { enqueueSnackbar } = useSnackbar();

	const openToast = useCallback(
		(props: ToastProps) => {
			enqueueSnackbar(props.message, {
				key: props.id,
				autoHideDuration: props.timeout,
				variant: props.severity,
				action: toastAction(props.severity),
			});
		},
		[enqueueSnackbar],
	);

	// Export for use outside of React components
	setOpenToastFunction(openToast);

	return { openToast };
}
