import AddFleetPaths from 'modules/irp/modules/supplements/modules/add_fleet/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps, { SupplementStepProps } from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function AddFleetSteps() {
	const { t } = useTranslation(['irp/supplements/add_fleet', 'irp/supplements']);

	const { supplementKey } = useTypedParams(AddFleetPaths);

	const steps: SupplementStepProps['steps'] = [
		{
			label: t('details.step'),
			route: AddFleetPaths.Details.buildPath({ supplementKey }),
			activeRoutes: [AddFleetPaths.Details.Jurisdictions.buildPath({ supplementKey })],
		},
		{ label: t('weight_groups.step'), route: AddFleetPaths.WeightGroups.buildPath({ supplementKey }) },
		{ label: t('vehicles.step'), route: AddFleetPaths.Vehicles.buildPath({ supplementKey }) },
		{
			label: t('irp/supplements:documentation.step'),
			route: AddFleetPaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('irp/supplements:verify.step'), route: AddFleetPaths.Verify.buildPath({ supplementKey }) },
		{ label: t('irp/supplements:submit.step'), route: AddFleetPaths.Submit.buildPath({ supplementKey }) },
	];

	return <SupplementSteps steps={steps} />;
}
