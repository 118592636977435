import NoPermission from 'core/components/NoPermission';
import { useAPI } from 'core/hooks';
import { useRazor } from 'core/providers/RazorProvider';
import { ApiError } from 'core/services/api';
import { openToast } from 'core/services/toast';
import deepmerge from 'deepmerge';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import RazorPaths from 'modules/razor/paths';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker, useNavigate } from 'react-router-dom';
import Supplement, { SupplementIncludeFields } from 'types/Supplement';

import { DialogContentText } from '@mui/material';
import Dialog from 'core/components/Dialog';

type SupplementContextProps = {
	supplement: Supplement | null;
	update: (supplement: Supplement) => void;
	reload: () => void;
	loading: boolean;
};

const DefaultSupplementContext: SupplementContextProps = {
	supplement: null,
	update: () => null,
	reload: () => null,
	loading: true,
};

const SupplementContext = createContext<SupplementContextProps>(DefaultSupplementContext);

export interface SupplementProviderProps {
	supplementKey: string;
	includes?: SupplementIncludeFields;
}

export function SupplementProvider({ supplementKey, includes, children }: PropsWithChildren<SupplementProviderProps>) {
	const supplementsService = useAPI(SupplementsService);
	const { t } = useTranslation(['irp/supplements']);
	const navigate = useNavigate();
	const { setAccountKey } = useRazor();

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [supplement, setSupplement] = useState<Supplement | null>(null);
	const [error, setError] = useState<ApiError | null>(null);

	const update = useCallback(
		(updatedSupplement: Supplement) => setSupplement(deepmerge(supplement || {}, updatedSupplement)),
		[supplement],
	);

	const reload = useCallback(() => {
		setLoading(true);
		setError(null);

		return supplementsService
			.getBySupplementKey(supplementKey, includes)
			.then((v) => {
				setSupplement(v);
				if (v.accountKey) setAccountKey(v.accountKey);
				return v;
			})
			.catch((err) => {
				if (err instanceof ApiError) {
					setError(err);
				}

				throw err;
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [includes, supplementKey, supplementsService]);

	// TODO: Redirect to correct supplement module if we are in the wrong one

	useEffect(() => {
		if (!supplementKey) return;

		reload().then((resp) => {
			switch (resp.status.code) {
				case 'O':
				case 'W':
					break;
				default:
					openToast({
						id: `redirect-dialog:${resp.key}`,
						message: t('irp/supplements:dialogs.redirect.body'),
						severity: 'success',
					});
					navigate(
						`${RazorPaths.Manage.Supplements.buildPath({})}?${RazorPaths.Manage.Supplements.buildSearch({
							supplementKeyId: resp.key,
							accountKeyId: resp.accountKey,
						})}`,
					);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [includes, supplementKey]);

	const value = useMemo<SupplementContextProps>(() => {
		return { supplement, update, reload, loading };
	}, [supplement, update, reload, loading]);

	// If there is an error, display it
	if (error) return <NoPermission />;

	return <SupplementContext.Provider value={value}>{children}</SupplementContext.Provider>;
}

export function useSupplement(): SupplementContextProps {
	const context = useContext(SupplementContext);
	if (context === DefaultSupplementContext) {
		throw new Error('useSupplement must be used within a SupplementProvider');
	}

	return context;
}

export interface SupplementBlockerProps {
	basePath: {
		buildPath: (params: { supplementKey: string }) => string;
	};
	title?: string;
	bodyTexts?: string[];
}

export default function SupplementBlocker({
	basePath,
	title,
	bodyTexts,
	children,
}: PropsWithChildren<SupplementBlockerProps>) {
	// Hooks
	const { t, i18n } = useTranslation('irp/supplements');
	const { supplement } = useSupplement();

	const isWorkflow = (pathname: string) =>
		pathname.startsWith(basePath.buildPath({ supplementKey: supplement?.key || '' }));

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (nextLocation.state?.bypass) return false;
		return isWorkflow(currentLocation.pathname) && !isWorkflow(nextLocation.pathname);
	});

	return (
		<>
			{children}

			<Dialog
				title={title || t('dialogs.close.title')}
				severity="error"
				maxWidth="xs"
				isOpen={blocker.state === 'blocked'}
				setIsOpen={() => blocker.reset && blocker.reset()}
				confirmLabel={t('core:buttons.close')}
				cancelLabel={t('core:buttons.stay')}
				onConfirm={() => blocker.proceed && blocker.proceed()}
			>
				{bodyTexts && bodyTexts?.length > 0 ? (
					bodyTexts?.map((text, i) => (
						<DialogContentText key={text} gutterBottom={i < bodyTexts.length - 1}>
							{text}
						</DialogContentText>
					))
				) : (
					<>
						<DialogContentText gutterBottom>
							{t(i18n.exists('dialogs.close.body1') ? 'dialogs.close.body1' : 'irp/supplements:dialogs.close.body1')}
						</DialogContentText>
						<DialogContentText>
							{t(i18n.exists('dialogs.close.body2') ? 'dialogs.close.body2' : 'irp/supplements:dialogs.close.body2')}
						</DialogContentText>
					</>
				)}
			</Dialog>
		</>
	);
}
