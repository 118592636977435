import ApiService, { ApiErrorProps } from 'core/services/api';
import Carrier, { CarrierContact, CarrierFields, CarrierIncludeFields } from 'types/Carrier';
import Fleet from 'types/Fleet';
import { IrpRegistrations } from 'types/IrpRegistration';
import Program from 'types/Program';

// TODO move to Carriers module when it's created

export default class CarriersService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/carriers`);
	}

	async getByAccountKey(accountKey: string, include?: CarrierIncludeFields, opts?: ApiErrorProps): Promise<Carrier> {
		const { carrier } = await this.GET<{ carrier: Carrier }>(`/${accountKey}`, opts, {
			params: {
				include: include ? Object.keys(include).join(',') : undefined,
			},
		});
		return carrier;
	}

	async getContact(accountKey: string, program?: Program): Promise<CarrierContact> {
		return this.GET<CarrierContact>(`/${accountKey}/contact`, undefined, {
			params: { program },
		});
	}

	async getIrpRegistrations(accountKey: string): Promise<IrpRegistrations> {
		return this.GET<IrpRegistrations>(`/${accountKey}/irp`, undefined);
	}

	async update(key: string, fields: CarrierFields): Promise<Carrier> {
		return this.PUT<{ fields: CarrierFields }, Carrier>(`/${key}`, { fields });
	}

	async create(userKey: string, fields: CarrierFields): Promise<Carrier> {
		const { carrier } = await this.POST<{ userKey: string; carrier: CarrierFields }, { carrier: Carrier }>('', {
			userKey,
			carrier: fields,
		});
		return carrier;
	}

	async getFleets(accountKey: string, expiryYear: number): Promise<Fleet[]> {
		const { fleets } = await this.GET<{ fleets: Fleet[] }>(`/${accountKey}/fleets`, undefined, {
			params: { expiryYear },
		});
		return fleets;
	}
}
