import { DateFormat } from 'core/services/intl';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export default interface Date {
	year: number;
	month: number;
	day: number;
}

export interface DateRange {
	endDate: Date;
	startDate: Date;
}

export const DateValidationSchema = Yup.object().shape({
	year: Yup.number(),
	month: Yup.number(),
	day: Yup.number(),
});

const minDate = dayjs({ M: 0, d: 1, y: 1900 });

// prettier-ignore
export const DateValidations = {
	noFutureDate:
		(t: TFunction) =>
			(data: Partial<Date> | undefined, { createError, path }: Yup.TestContext) =>
				data && dayjs({ ...data, month: (data.month || 0) - 1 }).isAfter(dayjs())
					? createError({ path, message: t(`core:data.validation.datepicker.disableFuture`) })
					: true,
	minDate:
		(t: TFunction) =>
			(data: Partial<Date> | undefined, { createError, path }: Yup.TestContext) =>
				data && dayjs({ ...data, month: (data.month || 0) - 1 }).isBefore(minDate)
					? createError({
						path,
						message: t(`core:data.validation.datepicker.minDate`, minDate.format(DateFormat)),
					})
					: true,
};
// prettier-enable

export const dayjsToDate = (date?: dayjs.Dayjs): Date | null => {
	if (!date || !date.isValid()) return null;

	return {
		year: date.year(),
		month: date.month() + 1,
		day: date.date(),
	};
};
