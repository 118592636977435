import { Check, Close, ErrorOutline, InfoOutlined, WarningAmber } from '@mui/icons-material';
import { AlertProps, IconButton, Slide, SlideProps, styled, useTheme } from '@mui/material';
import { MaterialDesignContent, SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { PropsWithChildren } from 'react';

const DefaultToastTimeout = 6_000;

function ToastTransition(props: SlideProps) {
	const theme = useTheme();

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Slide {...props} direction="down" timeout={300} easing={theme.transitions.easing.easeInOut} />;
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
	'&': {
		minWidth: 'unset',
	},
	'&.notistack-MuiContent': {
		minWidth: 'unset',
	},
	'&.notistack-MuiContent-success': {
		backgroundColor: theme.palette.success.main,
	},
	'&.notistack-MuiContent-info': {
		backgroundColor: theme.palette.info.main,
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: theme.palette.warning.main,
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: theme.palette.error.main,
	},
}));

export default function ToastProvider({ children }: PropsWithChildren) {
	return (
		<SnackbarProvider
			autoHideDuration={DefaultToastTimeout}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			maxSnack={5}
			TransitionComponent={ToastTransition}
			iconVariant={{
				success: <Check fontSize="small" sx={{ mr: 1 }} />,
				info: <InfoOutlined fontSize="small" sx={{ mr: 1 }} />,
				warning: <WarningAmber fontSize="small" sx={{ mr: 1 }} />,
				error: <ErrorOutline fontSize="small" sx={{ mr: 1 }} />,
			}}
			Components={{
				success: StyledMaterialDesignContent,
				info: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
			}}
			preventDuplicate
		>
			{children}
		</SnackbarProvider>
	);
}

export function toastAction(variant: AlertProps['severity']) {
	return function ToastAction(snackbarId: SnackbarKey) {
		const { closeSnackbar } = useSnackbar();
		const theme = useTheme();

		return (
			<IconButton onClick={() => closeSnackbar(snackbarId)}>
				<Close fontSize="small" sx={{ color: theme.palette[variant || 'info'].contrastText }} />
			</IconButton>
		);
	};
}
