import { styled } from '@mui/material';

const Code = styled('code')(({ theme }) => ({
	fontSize: theme.typography.body2.fontSize,
	backgroundColor: theme.palette.grey[200],
	padding: theme.spacing(0.25, 0.5),
	borderRadius: theme.shape.borderRadius,
}));

export default Code;
