import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { PropsWithChildren, useCallback, useState } from 'react';

interface LoadingButtonProps extends MuiLoadingButtonProps {
	onClick: () => void | Promise<void>;
}

export default function LoadingButton({ children, onClick, ...props }: PropsWithChildren<LoadingButtonProps>) {
	const [loading, setLoading] = useState(false);

	const handleClick = useCallback(async () => {
		setLoading(true);
		try {
			await onClick();
		} finally {
			setLoading(false);
		}
	}, [onClick]);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<MuiLoadingButton loading={loading} onClick={handleClick} {...props}>
			{children}
		</MuiLoadingButton>
	);
}
